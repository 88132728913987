import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/case-study-2.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";

export default function CaseStudy2() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [readMore, setReadMore] = useState({
    keytakeaway: false,
    client: false,
    challenges: false,
    solution: false,
    result: false,
  });

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[475px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left   ">
                <span style={{ color: "#A71AFB" }}>
                  Data collection & cleaning
                </span>
              </h3>

              <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4 ">
                Mon, Jan 22, 2024
              </h3>
              {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4 ">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px]   text-[10px] font-[400] text-left md:mt-4 mt-4 ">
                The aim behind this project was to gather thousands of data,
                clean and present in an actionable format to a young startup.
              </h3> */}
            </div>
            <div className="w-1/2">
              <img
                src={bannar}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:pl-20 px-6 pb-10  ">
          {/* <hr className="h-[2px] mt-6" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[100%]">
              <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Summary :{" "}
                </b>
                The aim behind this project was to gather thousands of data,
                clean and present in an actionable format to a young startup.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The client:{" "}
                </b>
                A mid-size Social Impact Start-up.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  About the Company:{" "}
                </b>
                The company serves both NGOs and Sponsors to amplify the impact
                of social-spend. They leverage technology to solve the
                cross-cutting problems of the social sector.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Challenge:{" "}
                </b>
                There is a lack of credible and rich data source for social
                sector companies (NGOs). The company was forced to devote a
                significant amount of time and resources to collecting
                preliminary information about the NGOs in India. The said effort
                had resulted in a weekly exercise of having to go through 9 data
                repositories to manually collect data.{" "}
                {readMore.challenges && (
                  <>
                    The resultant data had a significantly high rate of
                    human-error and would also require a secondary and final
                    proof-reading prior to being called “actionable” with
                    confidence. The company was in the growth-phase and thus
                    required this process to be automated. The final data should
                    have a very low error tolerance and should be immediately
                    actionable by the teams.
                  </>
                )}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      challenges: !readMore.challenges,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.challenges ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The P42L Solution:{" "}
                </b>
                During our meetings & discussions with the relevant stakeholders
                at the company, it was apparent that they had a “data problem”.
                <br />
                <br />
                We proposed a three-step solution to their business-critical
                problem:
                <br />
                <br />
                1.Automated Data Collection (via scrapers, crawlers, and RPA
                interactions) <br />
                2.Setting-up of rules for automated cleaning of the collected
                data
                
                {readMore.solution && (
                  <>
                  <br />
                    3.Creating a Data Lake and a Data Pipeline to feed into
                    their existing system
                    <br />
                    <br />
                    Result: Took a total of 110 working days to develop and
                    deliver the software to the client. The organization
                    received a total of 2,000,000 actionable data points. The
                    same was delivered to them in a NoSQL database and a
                    corresponding data feeding API. The API currently feeds into
                    their website, CRM, Project Management, and Data Analysis
                    software.
                    
                  </>
                )}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      solution: !readMore.solution,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.solution ? "Read less" : "Read more..."}
                </button>
                <br />
                    <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Key Takeaways
                </b>
                <br />
                <br />
                -Created a credible data source
                <br />
                <br />
                Through data scraping and web crawling, huge amounts of required
                data were validated and extracted. It was well-structured and
                reliable. Eliminating manual data & cleansing provided
                error-free data and made it readily actionable.
                
                {readMore.keytakeaway && (<>
                  <br />
                <br />
                Improved operational efficiency and optimizing marketing, the
                company could gain a competitive advantage.
                <br />
                <br />
                -Saved man-hours
                <br />
                <br />
                The earlier manual collection of data has been replaced with
                automated data collection using RPA technology. Automating data
                collection enabled ingesting, filing, and preparing information
                with elevated accuracy. This saved valuable man-hours and the
                team could spend their efforts on strategic and more productive
                tasks.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      keytakeaway: !readMore.keytakeaway,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.keytakeaway ? "Read less" : "Read more..."}
                </button>
              </p>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
