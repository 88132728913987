import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/case-study-4.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";

export default function CaseStudy1() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [readMore, setReadMore] = useState({
    keytakeaway: false,
    client: false,
    challenges: false,
    solution: false,
    result: false,
  });
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[479px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left   ">
                <span style={{ color: "#A71AFB" }}>
                  Data-Driven Market Research & RPA
                </span>
              </h3>

              <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4 ">
                Fri, April 26, 2024
              </h3>
              {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4 ">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px]   text-[10px] font-[400] text-left md:mt-4 mt-4 ">
                Launching e-commerce business for leading manufacturing
              </h3> */}
            </div>
            <div className="w-1/2">
              <img
                src={bannar}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div  className="md:pl-20 px-6 pb-10  ">
          {/* <hr className="h-[2px] mt-6" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[100%]">
              <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
              <b className="border-l-[5px] pl-3 border-[#A71AFB]"> Summary: </b>                Launching e-commerce business for leading manufacturing

                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]"> The Client: </b>Makin Laboratories, a leading pharmaceutical
                manufacturer in Indore, MP, India.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">About the Company: </b>With the mission of “Moving towards a
                healthy world”, the client is the 8th WHO-GMP certified (in
                Herbal & Nutraceutical Healthcare) facility in the world. The
                company also exports to countries like Uganda, Kenya, Ethiopia,
                the Philippines, Liberia, Malaysia, Russia, UAE, and Ghana.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">The Challenge: </b>The company wanted to launch an e-Commerce
                Store along with selling on popular eCommerce aggregator
                platforms in India.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">The P42L Solution:</b> Our meeting consolidated our original
                thought that the client needs a data-driven approach towards
                product and market research and formulate an informed execution
                strategy to help grow their businesses.
                {readMore.solution &&(<>
                  <br />
                <br />
                We proposed a 5-step solution and execution strategy:
                <br />
                <br />
                1. Automated data scraping and RPA for data collection for
                competing brands
                <br />
                2. Deep competition analysis (via text and product feedback
                sentiment analysis)
                <br />
                3. Creating a Time-Series Analysis to map a competing brand’s
                product growth
                <br />
                4. Forming a go-to-market strategy and assistance with marketing
                & branding
                <br />
                5. MIS Reporting Platform
                
                </>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      solution: !readMore.solution,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.solution ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Result: </b>P42L completed the project in a duration of 60
                working days. Our final deliverables included:
                <br />
                <br />
                1. The brand launched its eCommerce channel
                <br />
                2. Created a significant share of the market in a competitive
                and cost-effective way amongst its peers
                <br />
                3. The data also aided in the analysis of the right market and
                sales channels.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Key Takeaways</b>
                <br />
                <br />
                - Launched e-commerce channel
                <br />
                <br />
                Launching an e-commerce channel helped the company gain new
                customers and better exposure to the market. It reduced costs in
                many ways. Further, the company could enable deals and offers
                through the channel. 
                {readMore.keytakeaway && (<>
                  The channel also turned into a source of
                abundant information.
                <br />
                <br />
                -Refined business strategies
                <br />
                <br />
                Implementing automated data scraping and RPA helped in product
                and market research, which provided a competitive edge to the
                company. Through data-driven market research, the company could
                formulate better marketing and sales strategies. Time series
                analysis aided in understanding the market trends.
                <br />
                <br />
                -Better operations management
                <br />
                <br />
                MIS reporting platform allowed them to easily extract
                information from various management systems and deliver reports
                to operational managers. It provided a complete view of business
                processes and identify areas of improvement leading to enhanced
                decision making.
                </>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      keytakeaway: !readMore.keytakeaway,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.keytakeaway ? "Read less" : "Read more..."}
                </button>
              </p>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
