import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/case-study-6.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";

export default function CaseStudy6() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [readMore, setReadMore] = useState({
    keytakeaway: false,
    client: false,
    challenges: false,
    solution: false,
    result: false,
  });
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[479px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left   ">
                <span style={{ color: "#A71AFB" }}>
                  Automated HRMS for blue collar workers
                </span>
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4 ">
                Fri, Dec 15, 2023
              </h3>
              {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4 ">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px]   text-[10px] font-[400] text-left md:mt-4 mt-4 ">
                This time, we were approached by an autonomous political think
                tank of the country to create models for sentiment analysis.
              </h3>*/}
            </div>
            <div className="w-1/2">
              <img
                src={bannar}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:pl-20 px-6 pb-10  ">
          {/* <hr className="h-[2px] mt-6" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[100%]">
              <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Summary</b>
                <br />
                <br />
                This time, we were approached by an autonomous political think
                tank of the country to create models for sentiment analysis.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Client
                </b>
                <br />
                <br />
                A rapidly growing organization in the service industry, faced
                the challenge of hiring a large number of employees to meet
                their expansion plans. They sought to streamline their
                recruitment process and efficiently manage the entire lifecycle
                of bulk hiring, from sourcing candidates to {readMore.client && (<>onboarding and
                  employee data management. To overcome these challenges, they
                  approached us to develop a customized HRMS (Human Resource
                  Management System) tailored specifically for bulk hiring.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      client: !readMore.client,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.client ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Challenge
                </b>
                <br />
                <br />
                <b> Manual and Time-Consuming Process: </b> The existing
                recruitment process relied heavily on manual paperwork and
                spreadsheets, leading to inefficiencies, errors, and delays.
                They needed an automated system to streamline and expedite the
                hiring process.
                {readMore.challenges && (<><br />
                  <br />
                  <b>Centralized Candidate Database: </b> With a high volume of
                  applicants, the client struggled to organize and track candidate
                  information effectively. They required a centralized database to
                  store and manage candidate profiles, resumes, assessments, and
                  interview feedback.
                  <br />
                  <br />
                  <b>Efficient Sourcing and Screening: </b> The client needed an
                  efficient mechanism to source candidates from multiple channels,
                  including job portals, referrals, and recruitment agencies. They
                  also required automated screening tools to evaluate candidate
                  profiles and identify the most suitable candidates.
                  <br />
                  <br />
                  <b>Collaboration and Communication:</b> The client sought a
                  platform that facilitated seamless collaboration and
                  communication among hiring managers, recruiters, and
                  interviewers. They needed features such as interview scheduling,
                  feedback sharing, and candidate status updates.
                  <br />
                  <br />
                  <b>Compliance and Reporting: </b> Compliance with labor laws and
                  regulatory requirements was crucial for the client. They
                  required an HRMS that could generate accurate reports on hiring
                  activities, applicant diversity, and compliance metrics.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      challenges: !readMore.challenges,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.challenges ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Solution:
                </b>{" "}
                <br />
                <br /> <b>Automated Recruitment Workflow:</b> We designed and
                implemented an automated workflow that streamlined the entire
                recruitment process, from job requisition to offer acceptance.
                The system automated various tasks, such as job posting,
                application screening, interview scheduling, and candidate
                shortlisting, reducing manual effort and accelerating the hiring
                timeline.
                {readMore.solution && (<><br />
                  <br />
                  <b>Candidate Database and Talent Pool:</b> We built a
                  centralized candidate database where all applicant information,
                  resumes, and assessments were stored. The system allowed
                  recruiters to search, filter, and manage candidate profiles
                  efficiently. Additionally, a talent pool feature enabled the
                  client to maintain a database of potential candidates for future
                  hiring needs.
                  <br />
                  <br />
                  <b>Advanced Sourcing and Screening:</b> We integrated the HRMS
                  with popular job portals, social media platforms, and
                  recruitment agencies to enable seamless candidate sourcing. We
                  also implemented advanced screening mechanisms, including resume
                  parsing, keyword matching, and customized assessments, to
                  efficiently evaluate candidate qualifications and suitability.
                  <br />
                  <br />
                  <b>Collaboration and Communication Tools: </b> The HRMS included
                  collaboration and communication features that facilitated
                  seamless interaction among hiring managers, recruiters, and
                  interviewers. It included capabilities for interview scheduling,
                  feedback sharing, candidate status updates, and notifications,
                  ensuring effective communication throughout the hiring process.
                  <br />
                  <br /> Compliance and Reporting Dashboard: We developed a robust
                  compliance and reporting dashboard that provided real-time
                  insights into hiring metrics, applicant diversity, and
                  compliance status. The system generated customizable reports,
                  ensuring the client remained compliant with labor laws and
                  regulatory requirements.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      solution: !readMore.solution,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.solution ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Result:
                </b>{" "}
                <br />
                <br /> <b>Streamlined and Accelerated Hiring Process: </b> The
                automated recruitment workflow significantly reduced manual
                effort and streamlined the hiring process. The client
                experienced faster candidate sourcing, screening, and selection,
                resulting in reduced time-to-fill positions.
                <br />
                <br />
                <b> Improved Candidate Management:</b> The centralized candidate
                database and talent pool feature allowed recruiters to
                efficiently manage candidate profiles and maintain a repository
                of potential candidates. This improved candidate tracking,
                engagement
              </p>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${modals ? "translate-y-[0px]" : "translate-y-[240px]"
          } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
                } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
