import React from "react";
import Navbar from "../../components/navbar";
import img1 from "../../assets/images/homeimg1.png";
import img2 from "../../assets/images/homeimg2.png";
import img3 from "../../assets/images/homeimg3.png";
import logo1 from "../../assets/images/logo2.png";
import logo2 from "../../assets/images/logo3.png";
import Footer from "../../components/footer";
import bannar from "../../assets/images/About Us.svg";
import { useState } from "react";
import Hover from "react-3d-hover";
import { useNavigate } from "react-router-dom";
const AboutUs = () => {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>About us</span>
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
              We're the dreamers, the builders & the doers

              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Our Story
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
            At Project42 labs we sketch wireframes, write endless lines of
                code, build algorithms, and design strategies so that our
                clients can apply critical technologies with ease and
                simplicity.Project 42 Labs was conceived after we experienced
                the service industry delivering on incessant delays and quality.
                This company shaped out of the personal experience of the
                arduous journey of custom software development. Over a cup of
                coffee, we incubated Project 42 Labs to enable transparency and
                honesty in this segment.Our software development process
                stretches from choosing the right framework and defining the
                overall business logic. We become your partners with the
                overarching goal of simplifying technology.If you are a legacy
                business that requires a technology roadmap or a start-up that
                needs expertise, we are here to provide end-to-end customized
                technology solutions that let you stay ahead of the technology
                curve. Our product development team with a wide array of
                services such as Android/ IOS App development, chatbots, machine
                learning solutions, web development have got you covered.
              
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Our Mission : Keeping Technology Simple
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 pb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
            Taking a leadership position by developing differentiated
                    technologies that aid in a growth-centric ecosystem focusing
                    on scalability & affordability.
            </p>
            {/* </div> */}
          </div>
        </div>
        
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
