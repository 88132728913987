import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/case-study-5.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";

export default function CaseStudy5() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [readMore, setReadMore] = useState({
    keytakeaway: false,
    client: false,
    challenges: false,
    solution: false,
    result: false,
  });
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[479px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left   ">
                <span style={{ color: "#A71AFB" }}>
                  Building a ePublisher and eDistribution platform
                </span>
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4 ">
                Mon, Nov 27, 2023
              </h3>
              {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4 ">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px]   text-[10px] font-[400] text-left md:mt-4 mt-4 ">
                This time, we were approached by an autonomous political think
                tank of the country to create models for sentiment analysis.
              </h3> */}
            </div>
            <div className="w-1/2">
              <img
                src={bannar}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:pl-20 px-6 pb-10  ">
          {/* <hr className="h-[2px] mt-6" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[100%]">
              <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Summary
                </b>
                <br />
                <br />
                This time, we were approached by an autonomous political think
                tank of the country to create models for sentiment analysis.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Client
                </b>
                <br />
                <br />
                A publishing company, a leading provider of law content, sought to transform its traditional publishing processes into a modern and efficient ePublishing platform. They aimed to transition from print-based publications to digital formats, providing other publication houses with enhanced accessibility, interactivity, and flexibility at their platform.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  {" "}
                  The work{" "}
                </b>
                <br />
                <br /> To build an eBooks distribution and reading platform for the client. The target client base are professionals from the legal domain. The target user base are drawn by minimal and highly functional UI and intuitive UX designs. The platform is accessible via the web browser and desktop (macOS, Windows OS & Linux OS).
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  {" "}
                  The Challenge{" "}
                </b>{" "}
                <br />
                <br />
                The client faced several challenges in building their ePublisher and eDistribution platform:
                <br />
                <br />
                <b>Content Conversion and Standardization: </b>The client needed
                to convert their existing library of books into e-book formats
                such as EPUB and MOBI while ensuring consistency across
                different devices and reading platforms.
                {readMore.challenges && (<><br />
                  <br />
                  <b> Secure Content Distribution:</b> The client sought a secure
                  distribution system that protected intellectual property rights,
                  prevented unauthorized copying or sharing of e-books, and
                  facilitated digital rights management (DRM).
                  <br />
                  <br /> <b>Multi-Platform Compatibility: </b>The platform needed
                  to support a wide range of devices and operating systems,
                  including mobile devices, tablets, e-readers, and web browsers,
                  to ensure a seamless reading experience for users.
                  <br />
                  <br /> <b>Robust Sales and Analytics: </b>The client desired a
                  comprehensive sales dashboard and analytics system to track book
                  sales, analyze user behavior, and provide valuable insights to
                  authors and retailers.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      challenges: !readMore.challenges,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.challenges ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  {" "}
                  The Solution{" "}
                </b>{" "}
                <br />
                <br />
                To address the client's requirements, our development team
                devised a comprehensive solution:
                <br />
                <br />
                <b>Content Conversion and Standardization:</b> We developed an
                automated content conversion pipeline that transformed
                manuscripts into industry-standard e-book formats, such as EPUB
                and MOBI. This pipeline ensured consistent formatting, layout,
                and compatibility across various e-reading devices and
                {readMore.solution && (<>platforms.
                  <br />
                  <br />
                  <b>Secure Content Distribution: </b>To ensure secure content
                  distribution, we implemented industry-standard DRM mechanisms,
                  such as digital watermarking and encryption, to protect e-books
                  from unauthorized access and distribution. We integrated with
                  leading DRM providers to enforce copyright protection and
                  control access to digital content.
                  <br />
                  <br /> <b>Multi-Platform Compatibility: </b>The platform was
                  developed as a responsive web application, allowing users to
                  access e-books seamlessly across a range of devices and
                  operating systems. We optimized the reading experience for
                  various screen sizes, ensuring readability and interactive
                  features regardless of the device used.
                  <br />
                  <br /> <b>Robust Sales and Analytics: </b>We integrated the
                  platform with a powerful sales and analytics module that
                  provided real-time data on book sales, revenue, and user
                  engagement. Authors and retailers had access to comprehensive
                  dashboards and reports, enabling them to make data-driven
                  decisions and track the performance of their titles.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      solution: !readMore.solution,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.solution ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]"> Result </b>
                <br />
                <br /> <b>Increased Market Reach:</b> The platform allowed the
                client to tap into the growing e-book market, expanding their
                reach to a global audience of digital readers, resulting in
                increased book sales and {readMore.result && (<>revenue.
                  <br />
                  <br />
                  <b>Efficient Publishing Workflow:</b> The streamlined publishing
                  workflow reduced manual effort, enabling authors to publish
                  their books more quickly and efficiently. The user-friendly
                  interface and real-time previews improved collaboration and
                  author satisfaction.
                  <br />
                  <br /> <b>Enhanced Content Protection:</b> The implemented DRM
                  mechanisms safeguarded intellectual property rights, preventing
                  unauthorized distribution and piracy. This ensured content
                  creators received fair compensation for their work.
                  <br />
                  <br />
                  <b> Seamless Reading Experience:</b> The multi-platform
                  compatibility ensured that e-books could be</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      result: !readMore.result,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.result ? "Read less" : "Read more..."}
                </button>
              </p>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${modals ? "translate-y-[0px]" : "translate-y-[240px]"
          } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
                } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
