import React from "react";
import Navbar from "../../../components/navbar";
import img1 from "../../../assets/images/frontlogo1.png";
import img2 from "../../../assets/images/java.png";
import img3 from "../../../assets/images/HTML5_logo.png";

import bannar from "../../../assets/images/Machine Learning.svg";

import Footer from "../../../components/footer";
import { useState } from "react";

export default function MobileApp() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>
                  {" "}
                  Mobile App Development
                </span>{" "}
                as service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                With the majority user share for mobile devices worldwide, it’s
                an important and powerful platform
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              At Project42 Labs, we understand the business benefits and
              possibilities of a great mobile app and we assist our clients in
              enriching their customer experience through personalised app
              content and interactive engagement.Our experts are skilled in
              developing fast, flexible and stable apps which don't lose
              simplicity at the same time.
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Mobile App Development Technologies
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4 md:ml-12 ml-6">
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                Winning the customer's heart
              </b>
              <br />
              <br />
              In this era of mobile e-commerce, ordinary stores are not enough
              to place companies among the market leaders. Friendly
              communication with the customer, customised offerings, live online
              chat support etc. has been enabled through mobileapps and has
              helped firms build customer loyalty.
              <br />
              <br />
              The mobile apps we've built have helped our clients in
              establishing effective interaction between them and their
              end-customer and introducing their products and services to the
              right set of audience. Subsequently, strengthening their business
              and augmenting their e-commerce success.
              <br /> <br /><br/>
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Succeeding all devices
              </b>{" "}
              <br />
              <br />
              Uniform look and feel of app interfaces always attract customers.
              So we concoct apps which are compatible with most mobile devices
              and operating systems. This also paves the way for quicker app
              development and makes it easier to sync updates across devices
              <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Prioritizing UX based development
              </b>{" "}
              <br />
              <br />
              Creating a User Interface that instantly attracts customers and
              put a lasting impact in the user's mind is substantial in app
              development.Engaging, easy to use, visually appealing and seamless
              navigation are some of our preferences while creating apps.
              <br />
              <br />
              Through our thorough research in perception, emotions and
              preferences of our client's customers, we are able to develop apps
              that satisfy the user's mind. This also plays an important role in
              the growth of the brand of our client through happy customers.{" "}
              <br /><br/><br/>
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Hybrid mobile app development
              </b>{" "}
              <br />
              <br />
              Hybrid apps that are built from special frameworks enable smooth
              interfaces between the app's interface and native features of the
              device. Our approach of writing and maintaining individual code
              bases for various platforms saves costs for our clients along with
              ease of development. Our hybrid apps can provide most of what a
              native app can offer along with keepingthe backend structure
              simple.
              <br /><br/><br/>
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Our approach
              </b>{" "}
              <br />
              <br />
              We care to understand the business goals of our clients in detail
              and build apps which align with those goals. For that, we study
              their business case thoroughly and probe the requirements of our
              clients and their customers to produce maximum mutual benefits out
              of the project.
              <br />
              <br />
              Our app development process stretches from choosing the right
              framework and defining the overall strategy to preparing the
              marketing technique to launch the app. Our strategy development
              process has proven to impart lots of competitive advantage for our
              clients.
              <br /> <br />
              <br />
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[14px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                Technology Stack
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div><div className="md:px-20 px-0">
        <div className="flex items-center">
          <div className="flex  w-full my-2  mx-[auto]">
            <div class=" flex justify-around w-full flex-row items-center">
              <div className="w-[180px] h-[60%] flex flex-col justify-center items-center">
                <img src={img1} alt="" />
              </div>
              <div className="w-[180px] h-[60%] flex flex-col justify-center items-center">
                <img src={img2} alt="" />
              </div>
              <div className="w-[160px] h-[180px] flex flex-col justify-center items-center">
                <img src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:px-20 px-6">
        <div className="md:flex justify-between items-center md:my-[50px] my-0">
          {/* <div className=""> */}
          <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
            <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
              {" "}
              Mobile Application Development at Project 42 Labs
            </b>
          </h3>
          {/* </div> */}
        </div>
      </div>
      <div className="md:px-20 px-6 pb-10">
        <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
          {/* <div className="md:w-[100%]"> */}
          <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
            As a team, we emphasize on user journey and experience before
            getting on with the development. This enables us to develop a
            thorough process and create transparency.
          </p>
          {/* </div> */}
        </div>
      </div>
      </div>
      
      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
