import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";
import bannar from "../../../assets/images/Backend development.svg";

import img2 from "../../../assets/images/homeimg2.png";
import logo1 from "../../../assets/images/Kubernetes.png";
import logo2 from "../../../assets/images/docker.png";
import logo3 from "../../../assets/images/aws.png";
import logo4 from "../../../assets/images/GCP.png";
import img3 from "../../../assets/images/homeimg3.png";

import Footer from "../../../components/footer";
import { useState } from "react";

export default function BackEnd() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>
                  Backend Web Development
                </span>{" "}
                as service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Rock-solid, reliable backend development ensures your business
                logic works as it should.
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-7">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Project42 Lab's proficiency in the backend programming language,
              framework utilization and database management along with our
              organisational skills has powered many high performing websites
              currently on the web.
              <br />
              <br />
              Either it be data collected from the user or retrieved by the user
              from the database, we understand the importance of backend
              development in the smooth exchange of information. So we provide
              complex yet high-quality backend codes to support the process.{" "}
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Backend development technologies
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4 md:ml-12 ml-6">
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Faultless web applications
              </b>{" "}
              <br />
              <br />
              At Project42 Lab, we pay special attention in picking the right
              programming language and web development framework that meets all
              needs of our client's project.
              <br />
              <br />
              Our refined web development tools enable us to build fast-loading
              and scalable web and mobile applications. Our team of expert
              developers have crafted some of the best websites with the best
              user experience, for our clients.
              <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                Dominating all platforms
              </b>
              <br />
              <br />
              We build and customize the backend of websites to facilitate it to
              run in varying hosting environments. By focusing on the seamless
              transition of backend from one frontend to another, we make the
              website ideal for multiple user interfaces. The quality and
              structure of the backend code we deliver, foster our clients to
              provide the best user experience for their customers.
              <br /> <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Upgrading the backend code
              </b>{" "}
              <br />
              <br />
              We assist our clients to modify their prevailing backend systems
              and technical architecture. We give utmost care in refracting and
              rewriting the backend code without interrupting the front end
              components.
              <br />
              <br />
              We rewrite the code to enhance the scalability, improve the
              performance and fix previous issues of the website and customize
              it according to the demands of our clients. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Boosting the project with API
              </b>{" "}
              <br />
              <br />
              We follow API-driven development for better accessibility and
              connecting different applications. This also leads to
              cloud-friendly applications that can easily integrate with other
              applications and resources in the cloud. <br /> <br />
              <br />
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-0">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                Technology Stack
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-3">
          <div className="flex items-center">
            <div className="flex  overflow-x-scroll scrollbar-hide mt-10 mb-[50px] md:mx-[auto]">
              <div class="flex flex-nowrap flex-row items-center">
                <div className="w-[200px] md:w-[285px] h-[160px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo1} alt="" />
                </div>
                <div className="w-[200px] md:w-[285px] h-[160px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo2} alt="" />
                </div>
                <div className="w-[200px] md:w-[285px] h-[140px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo3} alt="" />
                </div>
                <div className="w-[200px] md:w-[285px] h-[140px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo4} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* <hr className="mt-10" /> */}
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-0">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                DevOps with CI/CD Pipelines
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 px-6 md:pl-20 pb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Each software development activity goes through a release pipeline
              <br />
              <br />
              Automating building, testing, and deploying code at every small
              iteration, with minimal or no human intervention are parts of
              Continuous Methodologies. Continuous Integrations, Continuous
              Deployment & Continuous Delivery pipelines empower our developers
              to identify bugs and errors at an early stage in the development
              cycle.
              <br />
              <br />
              This allows us to control and balance:
              <br />
              <br />
              1. Velocity: develop & deploy products or code quickly
              <br />
              2. Quality: deploy production level code up to the highest
              standards.
              <br />
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
