import Navbar from "../../../components/navbar";
import bannar from "../../../assets/images/Process consulting.svg";
import Footer from "../../../components/footer";
import React, { useState } from "react";

export default function AwsManagement() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#000000] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>AWS Management </span> as
                service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                "Unlocking Efficiency. Maximizing Potential with product
                engineering"
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                Introduction
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Our process consultants examine, analyse, design, and execute
              optimised processes using tried-and-true methodology, industry
              best practises, and a data-driven approach. We work together with
              your team to build solutions that are applicable, long-lasting,
              and consistent with the goals and culture of your company.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                Steps of process consulting
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:pl-20 px-6">
          <div className="md:px-20 px-6 ">
            <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10 pr-3">
              <div className="md:w-[100%]">
                <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[14px] font-[300] md:mt-8 mt-4">
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 1: Initial Assessment and Understanding
                  </b>
                  <br /> <br />
                  The process consulting engagement begins with a thorough
                  understanding of your organization's product engineering
                  processes. Project 42 Labs will engage with key stakeholders,
                  including product managers, engineers, and designers, to
                  gather essential information. We aim to gain insights into
                  your current processes, challenges, and goals. <br />
                  <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 2: Data Collection and Analysis
                  </b>
                  <br /> <br />
                  In this phase, we collect and analyze relevant data related to
                  your product engineering processes. This includes performance
                  metrics, project documentation, development methodologies, and
                  feedback from stakeholders. Through data analysis, we identify
                  areas for improvement, bottlenecks, and inefficiencies within
                  the existing processes. <br /> <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 3: Identify Process Improvement Opportunities
                  </b>
                  <br /> <br />
                  Based on the data analysis, we identify specific process
                  improvement opportunities in product engineering. This
                  involves mapping out the current processes, identifying pain
                  points, and determining the desired future state. We work
                  closely with your team to align the improvement opportunities
                  with your organization's objectives and industry best
                  practices. <br /> <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 4: Process Design and Optimization
                  </b>
                  <br /> <br />
                  In this phase, we design and optimize the product engineering
                  processes to address the identified improvement opportunities.
                  Our consultants leverage their expertise in product
                  development methodologies, agile practices, and industry
                  standards. We focus on streamlining workflows, improving
                  collaboration, and incorporating efficient feedback loops.
                  <br /> <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 5: Implementation Planning
                  </b>
                  <br /> <br />
                  Once the optimized processes are defined, we develop a
                  comprehensive implementation plan. This plan outlines the
                  necessary steps, timelines, and resource requirements for
                  successfully implementing the new processes. We collaborate
                  with your team to ensure that the implementation plan aligns
                  with your organization's structure, resources, and priorities.
                  <br /> <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 6: Process Implementation and Training
                  </b>
                  <br /> <br />
                  During the implementation phase, our consultants work closely
                  with your team to facilitate a smooth transition to the
                  optimized product engineering processes. We provide training,
                  coaching, and support to key stakeholders and team members
                  involved in the new processes. This ensures that everyone
                  understands their roles, responsibilities, and the benefits of
                  the process changes.
                  <br /> <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 7: Monitoring and Evaluation
                  </b>
                  <br /> <br />
                  Following the implementation, we closely monitor the
                  effectiveness of the new product engineering processes. We
                  collect feedback from stakeholders, track performance metrics,
                  and assess the overall impact on product development. This
                  allows us to measure the success of the implemented changes
                  and identify any additional areas for refinement. <br />
                  <br /> <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Step 8: Continuous Improvement
                  </b>
                  <br /> <br />
                  Process consulting in product engineering is an ongoing
                  journey of continuous improvement. We believe in adapting
                  processes to changing market dynamics and emerging
                  technologies. Our consultants work with your organization to
                  establish feedback mechanisms, conduct regular reviews, and
                  implement further refinements as needed to optimize your
                  product engineering practices. <br /> <br /> <br />
                </p>
              </div>
            </div>

            {/* <hr className="mt-14" /> */}
          </div>

          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            <div className=" md:w-[50%]">
              <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
                <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                  Benefit of process consulting
                </b>
              </h3>
            </div>
          </div>
          <div className="md:pl-20 px-6 ">
            <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10 pr-3">
              <div className="md:w-[100%]">
                <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[14px] font-[300] md:mt-8 mt-4">
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Improved Efficiency
                  </b>
                  <br />
                  <br />
                  Our process consulting services streamline your software
                  development processes, eliminating bottlenecks and unnecessary
                  steps, leading to increased productivity and faster
                  time-to-market.
                  <br /> <br />
                  <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Enhanced Quality
                  </b>
                  <br />
                  <br />
                  By implementing best practices and quality assurance measures,
                  we help you deliver high-quality software products that meet
                  customer expectations and reduce the risk of defects and
                  rework.
                  <br /> <br />
                  <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Optimized Collaboration
                  </b>
                  <br />
                  <br />
                  Our consulting services foster better collaboration and
                  communication between teams, breaking down silos and improving
                  cross-functional coordination. <br /> <br />
                  <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Cost and Time Savings
                  </b>
                  <br />
                  <br />
                  Optimized processes result in reduced waste, improved resource
                  allocation, and better project management, leading to cost and
                  time savings in software development. <br /> <br />
                  <br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Increased Team Satisfaction
                  </b>
                  <br />
                  <br />
                  Our focus on team engagement, training, and empowerment
                  contributes to higher job satisfaction, morale, and motivation
                  among your software development teams. <br /> <br />
                  <br />
                </p>
              </div>
            </div>

            {/* <hr className="mt-14" /> */}
          </div>

          {/* <div>
          <div className="md:hidden">
            <h3 className="md:hidden text-[14px] font-[500] text-[#FFFFFF] mt-10">
              OUR CLIENTS
            </h3>
          </div>
          <div className="flex  overflow-x-scroll scrollbar-hide  md:mt-[80px]  mt-10 ">
            <div class="flex flex-nowrap flex-row items-center gap-6">
              <div className="w-[327px] hidden md:block">
                <h3 className="text-[#ffffff] text-[40px] font-[400]">
                  Our Clients
                </h3>
                <p className="text-[#ffffff] text-[26px] font-[300]">
                  Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.
                </p>
              </div>
              <div className="w-[277px] h-[162px] bg-[#FFFFFF] flex flex-col justify-center items-center">
                <img src={logo1} alt="" />
              </div>
              <div className="w-[277px] h-[162px] bg-[#FFFFFF] flex flex-col justify-center items-center">
                <img src={logo2} alt="" />
              </div>
              <div className="w-[277px] h-[162px] bg-[#FFFFFF] flex flex-col justify-center items-center">
                <img src={logo1} alt="" />
              </div>
              <div className="w-[277px] h-[162px] bg-[#FFFFFF] flex flex-col justify-center items-center">
                <img src={logo2} alt="" />
              </div>
              <div className="w-[277px] h-[162px] bg-[#FFFFFF] flex flex-col justify-center items-center">
                <img src={logo1} alt="" />
              </div>
              <div className="w-[277px] h-[162px] bg-[#FFFFFF] flex flex-col justify-center items-center">
                <img src={logo2} alt="" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center md:mt-[70px] mt-6">
            <div className="flex flex-row items-center pb-6 md:p-0">
              <span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.02979 11.0005L13.2002 17.1709L13.2002 4.83008L7.02979 11.0005Z"
                    fill="#FFD037"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9702 11.0005L8.7998 4.83008V17.1709L14.9702 11.0005Z"
                    fill="#FFD037"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
