import React from "react";
import Navbar from "../../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../../assets/images/Django.svg";
import img1 from "../../../../assets/images/django2.png";
import img2 from "../../../../assets/images/django.png";

import Footer from "../../../../components/footer";
import { useState } from "react";

export default function Django() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#000000] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>Django Development</span> as
                service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
              Rock-solid, reliable backend development ensures your business logic works as it should.
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20  px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Our professionals at Project42 Labs are experts at building high
              performing Django web apps with aesthetically pleasing design and
              error-free coding. The excellent documentation ability and
              scalability makes Django framework our developers' favourite. It's
              a python-based web framework which is versatile in nature and
              provides rapid development.
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Django Development
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4 md:ml-12 ml-6">
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Why do we use Django?{" "}
              </b>{" "}
              <br />
              <br />
              The Django framework offers high security and is search engine
              optimised. Its simplicity and reliability are well-liked by
              developers. It allows our developers at Project42 Labs to
              specifically focus on developing new components of an application.
              <br />
              As a batteries-included web framework, Django provides codes for
              common operations like database management, session management,
              templating etc. It's also easy to customise, scale and extend the
              web framework.
              <br />
              One of the best features of Django is that it accelerates the web
              development process and saves our client's development time and
              money.
              <br />
              <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                Stability matters
              </b>
              <br />
              <br />
              Our client's efforts on a project matter to us. So we pay
              attention to furnish them with the most high quality and stable
              output. Django enables us to meet these proposals.
              <br />
              <br />
              Django framework is well known for its stability and reliability.
              The existing framework will continue to work even with future
              releases. Along with stability as a priority, Django is also
              committed to continuous improvement.
              <br /> <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Best suited for MVPs
              </b>{" "}
              <br />
              <br />
              Using the Django framework for a project can be the best way to
              turn an idea into business reality. MVP (Minimum Viable Product)
              models developed using Django are highly scalable for further
              development.
              <br />
              <br />
              Django comes fully featured with integral tools to formulate more
              features for the MVP. Also, the high-security level of Django is
              ideal for the safety of a new project.
              <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Python powered
              </b>{" "}
              <br />
              <br />
              Python is a high-level programming language that is easy to read
              and write. It is open-source and has vast library support. Since
              Python is quite popular, Django projects can be easily assisted
              and modified by other developers. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Open-source
              </b>{" "}
              <br />
              <br />
              The overall development cost can be reduced since Django is an
              open-source web framework. So the already built framework will
              reduce much of the hassle of web development and the developers
              can focus on the designing process. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Django REST Framework
              </b>{" "}
              <br />
              <br />
              The Django REST Framework (DRF) is a well-featured toolkit for
              building web APIs. It's very flexible and its powerful
              serialization engine is compatible with both ORM and non-ORM data
              sources. It has pluggable and easy to customise parsers, emitters
              and authenticators.
              <br />
              <br />
              The Django REST Framework can be effortlessly altered to fulfil
              the requirements of a project and can be integrated into
              third-party libraries. <br /> <br />
              <br />
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-0">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                Technology Stack
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="flex items-center">
            <div className="flex  overflow-x-scroll scrollbar-hide my-2  mx-[auto]">
              <div class=" flex flex-nowrap gap-3 flex-row items-center">
                <div className="w-[180px] md:w-[285px] h-[160px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={img1} alt="" />
                </div>
                <div className="w-[180px] md:w-[285px] h-[160px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={img2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <hr className="h-[2px] md:mt-20 mt-10" /> */}

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-0">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Django Use Cases
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 pb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Django is an open-source technology that is well supported with
              documentation and support infrastructure. It is a swift and
              reliable infrastructure that aids faster and secure web
              application development.
              <br />
              From eCommerce sites to financial & health care applications, CRMs
              for transportation and booking, social media sites, Django can be
              used to build any platform that is functionality heavy.
              <br />
              Many platforms like The Washington Post, Reddit, Instagram, and
              many more use Django as their core technology infrastructure
              platform.
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden  hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
