import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/case-study-3.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";

export default function CaseStudy1() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [readMore, setReadMore] = useState({
    keytakeaway: false,
    client: false,
    challenges: false,
    solution: false,
    result: false,
  });

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[479px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left   ">
                <span style={{ color: "#A71AFB" }}>
                  Simplifying order management systems
                </span>
              </h3>

              <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4 ">
                Mon, Mar 4, 2024
              </h3>
              {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4 ">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px]   text-[10px] font-[400] text-left md:mt-4 mt-4 ">
                Centralizing the order management system of a legacy company by
                consolidating pan India orders from chain of multiple vendors
              </h3> */}
            </div>
            <div className="w-1/2">
              <img
                src={bannar}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:pl-20 px-6 pb-10  ">
          {/* <hr className="h-[2px] mt-6" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[100%]">
              <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Summary:{" "}
                </b>
                Centralizing the order management system of a legacy company by
                consolidating pan India orders from chain of multiple vendors
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Client:{" "}
                </b>
                A 60+ Years old leading lens manufacturer in India.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  About the Company:{" "}
                </b>
                A 60 years old lens manufacturing company was working on digital
                transformation to leverage technology and become more
                centralized in processes. It has established itself as one of
                the leading lens manufacturing in India now evolving to adapt to
                next-generation sustainability.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Challenge:{" "}
                </b>
                The company had a pre-existing multi-channel Order Management
                System (OMS). Orders via e-mail is a business-critical channel.
                The company received more than 4000 orders a day from its
                10,000+ strong Opticians Network spread across India. These
                orders were received by data entry operators and manually
                inputted into the OMS to be later processed for further
                manufacturing.{" "}
                {readMore.challenges && (
                  <>
                    This was “the” source of a major bottleneck in the order
                    processing and manufacturing cycle. The team of data entry
                    operators was not able to keep up with the number of orders
                    that needed to be manually entered into the OMS. The entered
                    data was prone to a significant human error.
                    <br />
                    <br />
                    Each error results in:
                    <br />
                    <br />
                    1. Wasted raw-material
                    <br />
                    2. Wasted man-hours
                    <br />
                    3. Delayed manufacturing line
                    <br />
                    4. Delayed shipment of the final order to the end-client
                    <br />
                    5. The increased cost of manufacturing and lower bottom-line
                    <br />
                    <br />
                    Furthermore, prescription lenses order management had a
                    unique problem of requirements of further calculation on the
                    Optician’s Rx values for the lenses. These calculations are
                    required to be fed into the OMS in a particular format for
                    the orders to be processed successfully and subsequently to
                    be manufactured correctly.
                  </>
                )}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      challenges: !readMore.challenges,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.challenges ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The P42L Solution:{" "}
                </b>
                Our discussions with the relevant stakeholders at the company,
                we proposed to design a software that:
                <br />
                <br />
                1. Ingest emails
                <br />
                2. Process attached orders
                <br />
                3. Extract data from the invoices
                {readMore.solution && (<><br />
                4. Clean and extracted data
                <br />
                5. Perform computations on extracted data
                <br />
                6. Alert the relevant floor managers of outliers
                <br />
                7. Divert different types of orders to relevant manufacturing
                pipelines.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      solution: !readMore.solution,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                 &nbsp;{readMore.solution ? "Read less" : "Read more..."}
                </button>
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Result: </b>
                P42L developed the software in 45 working days. Our delivery had
                3 elements: 1. A SQL based database
                <br />
                <br />
                1. Data API
                <br />
                2. Dashboard
                <br />
                <br />
                Our software currently continues to run successfully with a
                remarkable and acceptable tolerance of 7-12%.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Key KPIs Delivered
                </b>
                <br />
                <br />
                -Eliminated manual data entry
                <br />
                <br />
                By implementing OCR to read data, employees no longer had to
                manually enter data and were able to keep up with the orders.
                The process also eliminated human-errors in the data through
                improved data extraction and cleansing.
                {readMore.keytakeaway && (<>
                  Usage of SQL led to
                enriched database management.
                <br />
                <br />
                -Saved man-hours
                <br />
                <br />
                By excluding manual entry of data, the company could save
                several man-hours and this took away the burden of mundane
                tasks, from employees.
                <br />
                <br />
                -Improved processes
                <br />
                <br />
                Getting rid of the delay in manufacturing subsequently excluded
                the delay in the shipment of the order, thus improving the
                process as a whole.
                <br />
                <br />
                -Reduced manufacturing costs
                <br />
                <br />
                Saving a considerable amount of raw materials, which used to get
                wasted, itself reduced a significant amount of expenditure. The
                cost of manufacturing was considerably lowered by allocating
                human resources effectively through text processing. OMS started
                running in a more cost-effective manner and also enhanced
                customer satisfaction.</>)}
                <button
                  onClick={() =>
                    setReadMore((prev) => ({
                      ...prev,
                      keytakeaway: !readMore.keytakeaway,
                    }))
                  }
                  className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                >
                  &nbsp;{readMore.keytakeaway ? "Read less" : "Read more..."}
                </button>
              </p>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
