import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ContactUs from "./Pages/contactus/contactUs";
import Home from "./Pages/home/home";
import Api from "./Pages/services/webdevelopment/backend/api";
import DataScience from "./Pages/services/datascience/dataScience";
import Django from "./Pages/services/webdevelopment/backend/django";
import MachineLearning from "./Pages/services/machineLearning";
import MobileApp from "./Pages/services/mobiledevelopment/mobileApp";
import BackEnd from "./Pages/services/webdevelopment/backEnd";
import FrontEnd from "./Pages/services/webdevelopment/frontEnd";
import WebDevelopment from "./Pages/services/webdevelopment/webDevelopment";
import ReactApp from "./Pages/services/react";
import DataManagement from "./Pages/services/dataManagement";
import SoftwareDevelopment from "./Pages/services/softwareDevelopment";
import Blogs from "./Pages/company/blogs";
import AboutUs from "./Pages/company/aboutUs";
import BestPractices from "./Pages/company/bestPractices";
import Blog1 from "./Pages/company/Blogs/blog1";
import Blog2 from "./Pages/company/Blogs/blog2";
import Blog3 from "./Pages/company/Blogs/blog3";
import Blog4 from "./Pages/company/Blogs/blog4";
import Blog5 from "./Pages/company/Blogs/blog5";
import Blog6 from "./Pages/company/Blogs/blog6";
import Work from "./Pages/work/work";
import CaseStudy1 from "./Pages/work/case-studies/case-study-1";
import CaseStudy2 from "./Pages/work/case-studies/case-study-2";
import CaseStudy3 from "./Pages/work/case-studies/case-study-3";
import CaseStudy4 from "./Pages/work/case-studies/case-study-4";
import CaseStudy5 from "./Pages/work/case-studies/case-study-5";
import CaseStudy6 from "./Pages/work/case-studies/case-study-6";
import NodeJS from "./Pages/services/webdevelopment/backend/nodeJS";
import ProcessConsulting from "./Pages/services/processConsulting/process-consulting";
import TearmsOfService from "./Pages/TearmsOfService/TearmOfservice";
import AwsManagement from "./Pages/services/AwsManagement";
import ResourceAugmentation from "./Pages/services/ResourceAugmentation";
import Microservices from "./Pages/services/Microservices";
import MvpDevelopment from "./Pages/services/MvpDevelopment";
import CaseStudy7 from "./Pages/work/case-studies/caseStudy7";

function App() {

  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-TZ0M68EQR7', {
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} /> {/*done */}
        <Route
          path="/services/machine-learning"
          element={<MachineLearning />}
        />
        <Route path="/services/web-development" element={<WebDevelopment />} />
        <Route
          path="/services/frontend-web-development"
          element={<FrontEnd />}
        />
        {/*done */}
        <Route path="/services/backend-development" element={<BackEnd />} />
        {/*done */}
        <Route path="/services/data-science" element={<DataScience />} />
        {/*done */}
        <Route
          path="/services/mobile-application-development"
          element={<MobileApp />}
        />
        {/*done */}
        <Route path="/services/backend-development/api" element={<Api />} />
        <Route
          path="/services/backend-development/django-development"
          element={<Django />}
        />
        {/*done */}
        <Route
          path="/services/frontend-web-development/react"
          element={<ReactApp />}
        />
        {/*done */}
        <Route path="/services/data-management" element={<DataManagement />} />
        <Route
          path="/services/custom-software-development"
          element={<SoftwareDevelopment />}
        />
        {/*done */}
        <Route path="/company/blogs" element={<Blogs />} />
        <Route path="/company/about-us" element={<AboutUs />} /> {/*done */}
        <Route path="/company/best-practices" element={<BestPractices />} />
        {/*done */}
        <Route path="/company/blogs/blogid-1" element={<Blog1 />} />
        <Route path="/company/blogs/blogid-2" element={<Blog2 />} />
        <Route path="/company/blogs/blogid-3" element={<Blog3 />} />
        <Route path="/company/blogs/blogid-4" element={<Blog4 />} />
        <Route path="/company/blogs/blogid-5" element={<Blog5 />} />
        <Route path="/company/blogs/blogid-6" element={<Blog6 />} />
        <Route path="/company/work" element={<Work />} />
        <Route path="/company/work/case-study-1" element={<CaseStudy1 />} />
        {/*done */}
        <Route path="/company/work/case-study-2" element={<CaseStudy2 />} />
        {/*done */}
        <Route path="/company/work/case-study-3" element={<CaseStudy3 />} />
        {/*done */}
        <Route path="/company/work/case-study-4" element={<CaseStudy4 />} />
        {/*done */}
        <Route path="/company/work/case-study-5" element={<CaseStudy5 />} />
        {/*done */}
        <Route path="/company/work/case-study-6" element={<CaseStudy6 />} />
        <Route path="/company/work/case-study-7" element={<CaseStudy7 />} />
        {/*done */}
        <Route
          path="/services/backend-development/nodejs-development"
          element={<NodeJS />}
        />
        {/*done */}
        <Route
          path="/services/process-consulting"
          element={<ProcessConsulting />}
        />
        {/*done */}
        <Route path="/services/aws_management" element={<AwsManagement />} />
        <Route
          path="/services/resource_augmentation"
          element={<ResourceAugmentation />}
        />
        <Route
          path="/services/microservices"
          element={<Microservices />}
        />
        <Route
          path="/services/mvp_development"
          element={<MvpDevelopment />}
        />
        {/*done */}
        <Route path="/Tearms_of_services" element={<TearmsOfService />} />
        {/*done */}
      </Routes>
    </>
  );
}

export default App;
