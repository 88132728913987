import React from "react";
import Navbar from "../../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../../assets/images/Node Js Development.svg";
import img from "../../../../assets/images/Node Js Development.svg";

import Footer from "../../../../components/footer";
import { useState } from "react";

export default function NodeJS() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#000000] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>Node.js Development</span> as
                service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Rock-solid, reliable backend development ensures your business
                logic works as it should.
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
            Node.js is a powerful, open-source JavaScript runtime
                environment built on Chrome's V8 JavaScript engine. It allows
                you to execute JavaScript code outside the browser, on the
                server side. This means you can use JavaScript to build fast and
                scalable network applications.
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Why choose Node.js{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:pl-20 px-6">
          
          
          
          <div className="md:px-20 px-6 ">
            <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10 pr-3">
              <div className="md:w-[100%]">
                <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[14px] font-[300] md:mt-8 mt-4">
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Speed and Efficiency{" "}
                  </b>{" "}
                  <br /><br />
                  The non-blocking, asynchronous nature of Node.js enables fast
                  and efficient handling of requests, resulting in
                  high-performance applications.
                  <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Scalability{" "}
                  </b>
                  <br /><br />
                  Node.js excels at handling concurrent connections, making it a
                  great choice for applications that need to scale horizontally.
                  <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    JavaScript Everywhere{" "}
                  </b>
                  <br /><br />
                  With Node.js, you can use the same programming language,
                  JavaScript, both on the client and server sides, streamlining
                  development and improving code reusability.
                  <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Vibrant Ecosystem{" "}
                  </b>
                  <br /><br />
                  Node.js has a vast community and an extensive collection of
                  open-source packages available through NPM, providing
                  developers with a rich ecosystem to leverage in their
                  projects. <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Real-Time Capabilities{" "}
                  </b>{" "}
                  <br /><br />
                  Node.js' event-driven architecture and support for WebSockets
                  make it a top choice for building real-time applications that
                  require instant data updates.<br /><br /><br /><br />
                </p>
              </div>
            </div>

            {/* <hr className="mt-14" /> */}
          </div>

</div>

<div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Key Features of Node.js{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:pl-20 px-6 pb-10">

          <div className="md:px-20 px-6 pb-8 ">
            <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10 pr-3">
              <div className="md:w-[100%]">
                <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[14px] font-[300] md:mt-8 mt-4">
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Asynchronous and Event-Driven{" "}
                  </b>{" "}
                  <br /><br />
                  Node.js utilizes an event-driven, non-blocking I/O model. This
                  means that it is designed to handle a large number of
                  simultaneous connections without blocking the execution of
                  other requests. The asynchronous nature of Node.js allows for
                  highly efficient and scalable applications. <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    JavaScript Everywhere{" "}
                  </b>
                  <br /><br />
                  With Node.js, you can write both the server-side and
                  client-side code in JavaScript. This makes it easy to share
                  code between the front-end and back-end, resulting in faster
                  development and improved productivity. <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    NPM Ecosystem{" "}
                  </b>
                  <br /><br />
                  Node.js comes with the Node Package Manager (NPM), which is
                  the largest ecosystem of open-source libraries and modules.
                  With NPM, you have access to thousands of pre-built packages
                  that can be easily integrated into your projects, saving you
                  time and effort. <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Scalability{" "}
                  </b>
                  <br /><br />
                  Node.js is known for its ability to handle high concurrency.
                  By utilizing an event-driven architecture and a non-blocking
                  I/O model, it can efficiently handle thousands of concurrent
                  connections with minimal resources, making it an excellent
                  choice for building scalable applications. <br /> <br /><br />
                  <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                    Real-Time Applications{" "}
                  </b>{" "}
                  <br /><br />
                  Node.js is ideal for building real-time applications such as
                  chat applications, collaborative tools, and streaming
                  services. Its event-driven architecture and support for
                  WebSockets enable bidirectional communication between the
                  client and server, facilitating real-time data updates.
                </p>
              </div>
            </div>

            {/* <hr className="mt-14" /> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
