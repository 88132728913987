import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import img2 from "../../../assets/images/homeimg2.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";
import blogimg3 from "../../../assets/images/blog3.png"

export default function Blog3() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);

  const style = {
    background:
    "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
      <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-[#A71AFB] md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-20">
                AI across Industries
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] font-noto text-[14px] font-[400] text-left md:mt-12 mt-4 pl-20">
                By : Surya Narayan Dev
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] font-noto text-[12px] font-[400] text-left md:mt-12 mt-4 pl-20">
                Thur, April 11, 2024
              </h3>
            </div>
            <div className="md:w-1/2">
              <img
                src={blogimg3}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
            {/* <div className="z-10 w-[700px] h-[600px]" style={style}>
          <div className="bg-machine h-[579px] w-[630px] z-20 " >
            <img
              src={img1}
              alt=""
              className="md:w-full w-[210px] h-[180px] md:h-full"
            />
            
            </div>
            </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 ">
          {/* <hr className="h-[2px] mt-6" /> */}
          {/* <div className="md:flex justify-between items-center md:my-[50px] my-10">
            <div className=" md:w-[40%]">
              <h3 className="text-[#FFFFFF] md:text-[40px] font-san text-[14px] font-[500] text-left">
                Introduction
              </h3>
            </div>
            <div className="md:w-[60%] mt-4">
              <p className="md:text-[26px] text-[12px] tracking-wide font-noto font-[400] text-[#FFFFFF]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque velit tellus, efficitur sed sodales quis, cursus
                eget nulla. Curabitur eu ex ut ex lacinia consectetur. Aliquam
                elementum quis enim et finibus. Curabitur dapibus blandit orci,
                vulputate elementum dui sagittis eu. Duis felis velit, viverra
                sed faucibus sed, blandit eget diam. In non lectus nisl. Donec
                luctus ex ut arcu bibendum, a hendrerit nisi porttitor. Quisque
                quis feugiat risus, ut gravida enim. Aenean vitae tellus non
                ligula tincidunt porttitor.
              </p>
            </div>
          </div>
          <hr className="h-[2px]" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[80%]">
              {/* <h2 className="text-[#FFFFFF] md:text-[40px] font-san text-[14px] font-[400]">
                What can we do ?
              </h2> */}
              <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[16px] font-[300] md:mt-8 md:mr-[60px] mt-4">
                In the present decade, AI is changing our lives at a very fast
                rate. You agree with it or not, every day each one of us is
                using applications of AI in one way or another. Along with
                making day-to-day life easier, AI is also redeeming several
                productive hours and saving millions with it.
                <br />
                <br />
                The extent of AI across industries is mind-blowing. Today, every
                industry exploits this technology, which was impossible for most
                of the supercomputers a while ago. Just like us, you too will be
                amazed at the vast expanse of AI technology, which is going to
                build the future.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">AI in Communication</b>
                <br />
                <br />
                Social media apps use AI-powered facial recognition to identify
                people in photos posted on the platforms. The technology also
                provides personalized news feed according to the user’s
                interests and preferences. Automated audio to text transcription
                has also improved over time.
                <br />
                <br />
                Email spam filters are a perfect example of AI application. They
                work by interpreting words in the message and message metadata
                and also categorize emails into primary, social and promotion
                inboxes.
                <br />
                <br />
                Plagiarism checkers used for writings, work with the help of ML,
                by referring to a data source. It can also identify the original
                source of the plagiarized content.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">AI in Healthcare</b>
                <br />
                <br />
                Nowadays, several hospitals use AI for accurate diagnosis of eye
                and heart diseases. It predicts a patient’s risk of having a
                stroke by analyzing previous data. Going through health records
                using ML, helps professionals by creating insights to make
                better clinical decisions.
                <br />
                <br />
                Wearable healthcare technology has helped people in evaluating
                their own health and taking measures to improve it.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">AI in Entertainment & Lifestyle</b>
                <br />
                <br />
                Shortly, smart personal assistants like Siri, Alexa and Echo are
                going to rule our homes. They have improved people’s
                productivity to a great extent by integrating tasks digitally.
                <br />
                <br />
                AI is utilized by streaming services like Netflix, to provide
                highly accurate and personal recommendations based on customer's
                reactions to movies.
                <br />
                <br />
                One of the biggest leaps of AI can be seen in the gaming
                industry. It helps in predicting actions taken by the opponent
                and learns throughout the game, thus never repeating the same
                mistakes. This makes the game even more challenging.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">AI in Business</b>
                <br />
                <br />
                AI enables the automation of routine tasks and can save valuable
                labor-hours wasted on mundane tasks. AI techniques can boost
                customer support through chatbots and real-time assistance. It
                also helps in providing an enhanced online shopping experience
                by recommending relevant products and personalized search
                results.
                <br />
                <br />
                In the long run, AI will aid in studying market trends,
                anticipating future sales, and running operations efficiently.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">AI in Transportation </b>
                <br />
                <br />
                The first thing which comes to every mind while hearing AI in
                transportation is “self-driving” cars. Giant companies are
                competing to get ahead in self-driving technology. It is certain
                that self-driving cars will conquer the roads of the future.
                <br />
                <br />
                Currently, ride-sharing apps like Uber, use Machine Learning
                powered systems to determine the price of the ride, reduce
                waiting time for the customer etc. AI also helps in suggesting
                faster routes to destinations, by assessing hindrances in the
                way like heavy traffic, construction works etc.
                <br />
                <br />
                In airports, AI aid air traffic management during low-visibility
                conditions in the runway, hence eliminating the delay of flights
                during poor weather.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Introducing AI into your Business</b>
                <br />
                <br />
                All the applications of AI that we’ve discussed so far have
                strong use-cases in business. At Project42 Labs, we’ll help you
                to find where AI fits in your business and provide customized AI
                solutions. Our team of passionate professionals will expose your
                business operations to advanced technology, develop tailored
                remedies and ensure time-bound delivery of results.
              </p>
            </div>
            <div>
              <div className="w-full mt-[0px] md:mt-0">
                <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 ">
                  <Hover scale={1.05} perspective={700} speed={500}>
                    <div className="md:w-[240px]  md:h-[607px] md:px-4 px-2 md:py-4 md:mt-6 mb-10 mt-[-100px] group hover:bg-white hover:shadow-white hover:shadow-sm hover:scale-105 ">
                      <img src={img3} className="rounded-[100%]"></img>

                      <h3 className="text-[#FFFFFF] md:text-[18px] font-san text-[16px] group-hover:text-black font-medium md:mt-4 mt-2">
                        About the author
                      </h3>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[14px] group-hover:text-black font-[400] md:mt-3 mt-2">
                        Surya is the Founder & CTO of Project42 Labs. A King’s
                        College London alumnus, he has over 9 years of
                        experience in Data Science & Machine Learning and
                        building end-to-end solutions to meet business goals.
                        He’s usually found playing sports, reading and listening
                        to music when not working.
                      </p>
                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
