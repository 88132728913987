import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import img2 from "../../../assets/images/homeimg2.png";
import logo1 from "../../../assets/images/frontlogo1.png";
import logo2 from "../../../assets/images/angular.png";
import logo3 from "../../../assets/images/d3.png";
import logo4 from "../../../assets/images/frontlogo2.png";
import logo5 from "../../../assets/images/chart.png";
import img3 from "../../../assets/images/homeimg3.png";
import bannar from "../../../assets/images/Frontend development.svg";
import Footer from "../../../components/footer";
import { useState } from "react";
import { hover } from "@testing-library/user-event/dist/hover";

export default function FrontEnd() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>
                  Frontend Web Development
                </span>{" "}
                as service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Clean code, beautiful design and intuitive usability.
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-7">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
            <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] "> Introduction </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-7">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Intuitive, research-driven and user-first frontend design is the
              key to all good products. Thus, augmenting the front-end has
              become one of the priorities of all companies in order to optimize
              their website performance and deliver the best user experience.
              <br />
              With our best in class digital tools and years of expertise,
              Project42 Labs has delivered some of the most aesthetic and
              responsive frontend interfaces. Our frontend projects have enabled
              our clients to provide better visibility, navigation and
              transmission to their customers.
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
            <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">Our approach to frontend development & Technology focus</b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4 md:ml-12 ml-6">
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                User-driven UI/UX
              </b>{" "}
              <br /><br/>
              We bestow special focus on testing our products at every step of
              progress to terminate any possibility of shortcoming.
              <br /> Any issues in the frontend can hinder the customer
              experience which can affect the reputation of the firm. So we
              concentrate on assuring the reliability and quality of our product
              through advanced testing and code reviews, thus saving our
              client's valuable time and money. Performing frontend testing also
              builds confidence in deploying the project in both parties.
              <br /> We use Hotjar to analyse user-behaviour on our designs.
              Heatmaps, Conversion funnels and user feedback are taken into
              account to further optimise the design.
              <br /> <br /><br/>
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                Up to global standards
              </b>
              <br /><br/>
              Compatibility across different devices and operating systems are
              one of the key factors which determine the success of a website or
              web app. -<br />
              Our avant-garde frontend development tools help us to create
              fast-loading websites which have a standard response time, smooth
              navigation and are highly compatible. This entitles our client to
              deliver a streamlined buyer experience for their customers.
              <br /> <br />
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-0">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
            <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">Technology Stack</b>
            </h3>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-3">
          <div className="flex items-center">
            <div className="flex  overflow-x-scroll scrollbar-hide mt-10 mb-[50px] ">
              <div class="flex flex-nowrap flex-row items-center">
                <div className="w-[200px] md:w-[285px] h-[180px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo1} alt="" />
                </div>
                <div className="w-[200px] md:w-[285px] h-[180px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo2} alt="" />
                </div>
                <div className="w-[200px] md:w-[285px] h-[170px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo3} alt="" />
                </div>
                <div className="w-[180px] md:w-[285px] h-[160px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo4} alt="" />
                </div>
                <div className="w-[200px] md:w-[285px] h-[180px] md:h-[200px] flex flex-col justify-center items-center">
                  <img src={logo5} alt="" />
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-0">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
            <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] "> Delivering the best digital content </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 pb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Our content management system (CMS) software has assisted our
              clients in handling their enterprise's and web's digital content
              flawlessly.
              <br />
              <br />
              Project42 Lab's refined digital tools and strategic approach
              enables us to procure the most customized solutions for your
              content management.
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
