import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/main-logo1.png";
import pic1 from "../assets/images/arrow.png";
import { MdClose } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";

import d1 from "../assets/images/d1.png";
export default function Navbar({ modals, setModals }) {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [over, setover] = useState(false);

  const [navAction, setNavAction] = useState({
    navShow: false,
    service: false,
    company: false,
  });

  const navigate = useNavigate();

  const clickHandler = () => {
    setModal(!modal);
  };
  const clickHandler2 = () => {
    setModal2(!modal2);
  };

  const overHanndler = () => {
    setover(true);
  };

  const outHandler = () => {
    setover(false);
  };
  const style = {
    background: "#27232c50",
    // background: "#17171780",
  };
  const cblack = "#171717";
  const bg = {
    // background: "linear-gradient(175.29deg, #E9E9E9 0%, #A4A4A4 97.23%)",
    background: "#00000015",
  };
  return (
    <>
      <div
        // style={style}
        className="flex bg-[#27232c] flex-row items-center px-6 py-6 md:px-20 md:py-7 justify-between relative"
      >
        <div className="flex gap-2 md:gap-0 md:flex-col items-center justify-center">
          <div>
            <img
              src={logo}
              alt=""
              className="w-[80px] h-[25px] md:w-[200px] md:h-[60px] cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          {/* <p className="text-[#FFFFFF] text-[9px] md:text-[12px] font-san font-semibold text-center md:w-[90px] md:mt-2 ">
            PROJECT 42 LABS
          </p> */}
        </div>
        <div className="md:hidden">
          <span
            className=""
            onClick={() => {
              setModals((value) => !value);
              setNavAction((prev) => ({ ...prev, navShow: true }));
            }}
          >
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.16667 21.6804H19.8333C20.475 21.6804 21 20.8824 21 19.9071C21 18.9317 20.475 18.1337 19.8333 18.1337H1.16667C0.525 18.1337 0 18.9317 0 19.9071C0 20.8824 0.525 21.6804 1.16667 21.6804ZM1.16667 12.8137H19.8333C20.475 12.8137 21 12.0157 21 11.0404C21 10.0651 20.475 9.26706 19.8333 9.26706H1.16667C0.525 9.26706 0 10.0651 0 11.0404C0 12.0157 0.525 12.8137 1.16667 12.8137ZM0 2.17372C0 3.14906 0.525 3.94706 1.16667 3.94706H19.8333C20.475 3.94706 21 3.14906 21 2.17372C21 1.19839 20.475 0.400391 19.8333 0.400391H1.16667C0.525 0.400391 0 1.19839 0 2.17372Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
        <div className="hidden md:flex flex-row items-center gap-[130px] mr-80">
          <ul className="text-[#FFFFFF] font-san tracking-wider flex gap-14 text-[15px] font-medium ">
            <li
              onClick={() => navigate("/")}
              className="hover:text-[#A71AFB] cursor-pointer"
            >
              Home
            </li>
            <li
              onClick={clickHandler}
              className="hover:text-[#A71AFB] cursor-pointer relative"
            >
              <span>
                <div className="flex flex-row gap-2 items-center">
                  Services <FaAngleDown className="text-xl" />
                </div>
                {modal && (
                  <div
                    style={style}
                    className="absolute  w-[14rem] h-fit top-[40px] shadow-xl"
                  >
                    <div className="relative">
                      <span className="absolute top-[-12px] left-3 shadow-xl">
                        <svg
                          width="21"
                          height="16"
                          viewBox="0 0 21 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.09509 1.22484C9.2948 -0.383901 11.7052 -0.383904 12.9049 1.22484L20.3487 11.2066C21.8243 13.1853 20.4122 16 17.9438 16H3.05619C0.587823 16 -0.824337 13.1853 0.651288 11.2066L8.09509 1.22484Z"
                            fill={style.background}
                          />
                        </svg>
                      </span>
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black mt-[18px]"
                      onMouseOver={overHanndler}
                      onMouseOut={outHandler}
                    >
                      <div className="relative group">
                        <span
                          onClick={() =>
                            navigate("/services/custom-software-development")
                          }
                        >
                          <div className="flex flex-row gap-2 items-center">
                            Software Development
                            <FaAngleDown className="text-xl" />
                          </div>
                        </span>
                        <div
                          style={style}
                          className=" text-white hidden group-hover:block w-[284px] h-fit absolute py-2 left-full top-[-4px]  "
                          // onMouseEnter={overHanndler}
                          // onMouseLeave={outHandler}
                        >
                          <ul className="text-[14px] space-y-4 blurCustom  font-san  font-[500]">
                            <li
                              className=" h-[34px] pl-4 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                              onClick={() =>
                                navigate("/services/frontend-web-development")
                              }
                            >
                              Frontend Web Development
                            </li>
                            <div
                              className="  relative h-[34px] pl-4 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black group-foo"
                              onClick={() =>
                                navigate("/services/backend-development")
                              }
                            >
                              <span> Backend Web Development</span>
                            </div>

                            <li
                              className=" h-[34px] pl-4 pt-1 text-[#FFFFFF] hover:bg-white hover:text-black cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/services/backend-development/django-development"
                                )
                              }
                            >
                              Django Development
                            </li>

                            <li
                              className=" h-[34px] pl-4  pt-1  text-[#FFFFFF] hover:bg-white hover:text-black cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/services/frontend-web-development/react"
                                )
                              }
                            >
                              React Development
                            </li>
                            <li
                              className=" h-[34px] pl-4 pt-1 text-[#FFFFFF] hover:bg-white hover:text-black cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/services/backend-development/nodejs-development"
                                )
                              }
                            >
                              Node.Js Development
                            </li>
                            <li
                              className=" h-[34px] pl-4 pt-1 text-[#FFFFFF] hover:bg-white hover:text-black cursor-pointer"
                              onClick={() =>
                                navigate("/services/data-management")
                              }
                            >
                              Database Management
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* {over && ( */}

                      {/* )} */}
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/services/data-science")}
                    >
                      Data Science
                    </div>
                    {/* <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/services/machine-learning")}
                    >
                      Machine Learning
                    </div> */}
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() =>
                        navigate("/services/mobile-application-development")
                      }
                    >
                      Mobile Development
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/services/process-consulting")}
                    >
                      Process Consulting
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/services/aws_management")}
                    >
                      AWS Management
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() =>
                        navigate("/services/resource_augmentation")
                      }
                    >
                      Resource Augmentation
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/services/microservices")}
                    >
                      Microservices
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/services/mvp_development")}
                    >
                      MVP Development
                    </div>
                  </div>
                )}
              </span>
            </li>
            {/* <li onClick={() => navigate("")} className="hover:text-[#A71AFB] cursor-pointer">
              Solutions
            </li> */}
            <li
              onClick={() => navigate("/company/work")}
              className="hover:text-[#A71AFB] cursor-pointer"
            >
              Work
            </li>
            <li
              onClick={clickHandler2}
              className="hover:text-[#A71AFB] cursor-pointer"
            >
              <span>
                <div className="flex flex-row gap-2 items-center">
                  Company <FaAngleDown className="text-xl" />
                </div>
                {modal2 && (
                  <div
                    style={style}
                    className="absolute blurCustom w-[168px] h-[170px] top-[90px]"
                  >
                    <div className="relative">
                      <span className="absolute top-[-12px] left-3">
                        <svg
                          width="21"
                          height="16"
                          viewBox="0 0 21 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.09509 1.22484C9.2948 -0.383901 11.7052 -0.383904 12.9049 1.22484L20.3487 11.2066C21.8243 13.1853 20.4122 16 17.9438 16H3.05619C0.587823 16 -0.824337 13.1853 0.651288 11.2066L8.09509 1.22484Z"
                            fill={style.background}
                          />
                        </svg>
                      </span>
                    </div>

                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/company/about-us")}
                    >
                      About Us
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/company/blogs")}
                    >
                      Blogs
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black"
                      onClick={() => navigate("/company/best-practices")}
                    >
                      Best Practices
                    </div>
                    <div
                      className="w-full h-[34px] pl-4 mt-2 pt-1 cursor-pointer text-[#FFFFFF] hover:bg-white hover:text-black "
                      onClick={() => navigate("/contact-us")}
                    >
                      Contact Us
                    </div>
                  </div>
                )}
              </span>
            </li>
          </ul>
          {/* <button
            className="z-50 absolute top-10 right-9  w-[139px] h-[36px] bg-[#A71AFB] text-[#ffffff] text-4 font-medium cursor-pointer "
            onClick={() => navigate("/contact-us")}
          >
            Contact us
          </button> */}
        </div>
      </div>
      {navAction.navShow && (
        <div
          style={bg}
          className={`fixed  ${
            navAction.navShow ? "translate-x-[0px]" : "translate-x-[200px]"
          } md:hidden backdrop-blur-lg transition-all ease-in-out duration-500 z-50  py-5 px-3 right-0 top-0 h-[100vh] w-[80%]  `}
        >
          <div className="w-full flex justify-end items-start pb-2 border-b border-solid border-b-white ">
            <button
              onClick={() => {
                setNavAction((prev) => ({ ...prev, navShow: false }));
              }}
              className=""
            >
              <MdClose className="w-10 h-10 text-white" />
            </button>
          </div>
          <div className="relative mt-3 text-white font-medium text-base flex flex-col justify-between items-start gap-3  ">
            <button
              onClick={() => {
                navigate("/");
                // setNavAction((prev) => ({
                //   ...prev,
                //   service: !navAction.service,
                // }));
              }}
            >
              Home
            </button>
            <button
              onClick={() => {
                // navigate("/");
                setNavAction((prev) => ({
                  ...prev,
                  service: !navAction.service,
                }));
              }}
              className="  flex gap-2 items-center "
            >
              Services
              <FaAngleDown className="text-xl" />
            </button>
            {navAction.service && (
              <div className="ml-3 transition-all ease-out duration-2000 flex flex-col justify-between items-start gap-3 ">
                <div className=" group flex flex-col justify-between items-start gap-3 ">
                  <button className="  flex gap-2 items-center ">
                    <span
                      onClick={() => {
                        navigate("/services/custom-software-development");
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                        }));
                      }}
                    >
                      Software Development
                    </span>
                    <FaAngleDown className="text-xl" />
                  </button>
                  <div className="ml-4  flex-col justify-between items-start gap-3  hidden group-hover:flex ">
                    <button
                      onClick={() => {
                        navigate("/services/frontend-web-development");
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                          navShow: false,
                        }));
                      }}
                    >
                      Frontend Development
                    </button>
                    <button
                      onClick={() => {
                        navigate("/services/backend-development");
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                          navShow: false,
                        }));
                      }}
                    >
                      Backend Development
                    </button>
                    <button
                      onClick={() => {
                        navigate(
                          "/services/backend-development/django-development"
                        );
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                          navShow: false,
                        }));
                      }}
                    >
                      Django Development
                    </button>
                    <button
                      onClick={() => {
                        navigate("/services/frontend-web-development/react");
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                          navShow: false,
                        }));
                      }}
                    >
                      React Development
                    </button>
                    <button
                      onClick={() => {
                        navigate(
                          "/services/backend-development/nodejs-development"
                        );
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                          navShow: false,
                        }));
                      }}
                    >
                      Node.js Development
                    </button>
                    <button
                      onClick={() => {
                        navigate("/services/data-management");
                        setNavAction((prev) => ({
                          ...prev,
                          service: true,
                          navShow: false,
                        }));
                      }}
                    >
                      Database Management
                    </button>
                  </div>
                </div>

                <button
                  onClick={() => {
                    navigate("/services/data-science");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  Data Science
                </button>
                <button
                  onClick={() => {
                    navigate("/services/mobile-application-development");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  Mobile Development
                </button>
                <button
                  onClick={() => {
                    navigate("/services/process-consulting");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  Process Consulting
                </button>
                <button
                  onClick={() => {
                    navigate("/services/aws_management");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  AWS Management
                </button>
                <button
                  onClick={() => {
                    navigate("/services/resource_augmentation");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  Resource Augmentation
                </button>
                <button
                  onClick={() => {
                    navigate("/services/microservices");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  Microservices
                </button>
                <button
                  onClick={() => {
                    navigate("/services/mvp_development");
                    setNavAction((prev) => ({
                      ...prev,
                      service: true,
                      navShow: false,
                    }));
                  }}
                >
                  MVP Development
                </button>
              </div>
            )}

            <button
              onClick={() => {
                navigate("/company/work");
                setNavAction((prev) => ({ ...prev, service: true }));
              }}
              className=" "
            >
              Work
            </button>
            <button
              onClick={() => {
                // navigate("/contact-us");
                setNavAction((prev) => ({ ...prev, company: true }));
              }}
              className=" flex items-center gap-2 "
            >
              Company
              <FaAngleDown className="text-xl" />
            </button>

            {navAction.company && (
              <div className="ml-3 transition-all ease-out duration-2000 flex flex-col justify-between items-start gap-3 ">
                <button
                  onClick={() => {
                    navigate("/company/about-us");
                    setNavAction((prev) => ({
                      ...prev,
                      service: false,
                      company: false,
                    }));
                  }}
                >
                  About Us
                </button>

                <button
                  onClick={() => {
                    navigate("/company/blogs");
                    setNavAction((prev) => ({
                      ...prev,
                      service: false,
                      navShow: false,
                      company: false,
                    }));
                  }}
                >
                  Blogs
                </button>
                <button
                  onClick={() => {
                    navigate("/company/best-practices");
                    setNavAction((prev) => ({
                      ...prev,
                      service: false,
                      navShow: false,
                      company: false,
                    }));
                  }}
                >
                  Best Practices
                </button>
                <button
                  onClick={() => {
                    navigate("/contact-us");
                    setNavAction((prev) => ({
                      ...prev,
                      service: false,
                      navShow: false,
                      company: false,
                    }));
                  }}
                  className="text-[#333E4A] text-base font-medium"
                >
                  Contact Us
                </button>
              </div>
            )}
            <img
              src={d1}
              className="absolute top-[20rem] z-0 right-[6.75rem]"
            />
            <img
              src={d1}
              className="absolute top-[8rem] z-0 w-[80px] h-[88px] right-[1.75rem]"
            />
          </div>
        </div>
      )}
      <button
        className="z-50 fixed top-10 right-9 lg:flex justify-center items-center hidden  w-[139px] h-[36px] bg-[#A71AFB] text-[#ffffff] text-4 font-medium cursor-pointer rounded-lg "
        onClick={() => navigate("/contact-us")}
      >
        Contact us
      </button>
    </>
  );
}
