import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/case-study-1.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";

export default function CaseStudy1() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [readMore, setReadMore] = useState({
    summary: false,
    client: false,
    challenges: false,
    solution: false,
    result: false,
  });

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[475px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left">
                <span style={{ color: "#A71AFB" }}>
                  Sentiment Analysis for a Political Think Tank
                </span>
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4">
                Wed, Oct 18, 2023
              </h3>
              {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] text-[10px] font-[400] text-left md:mt-4 mt-4">
                This time, we were approached by an autonomous political think
                tank of the country to create models for sentiment analysis.
              </h3> */}
            </div>
            <div className="w-1/2">
              <img
                src={bannar}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:pl-20 px-6 pb-10 ">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-100%]">
              <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Summary</b>
                <br />
                <br />
                This time, we were approached by an autonomous political think
                tank of the country to create models for sentiment analysis.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Challenge
                </b>
                <br />
                <br />
                A leading political party was facing issues in converting their
                social media conversations into a useful format that could be
                used as data assets by the think tank. They wanted the
                conversations to provide touch points around which they could
                plan, design, and execute their political campaigns in the
                future.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Solution
                </b>
                <br />
                <br />
                Our solution-centric approach for the unique needs of this
                political party helped us provide them with the following
                solutions:
                <br />
                <br />
                1. We designed a SaaS-based set-up pipeline for automated
                conversion of speech to text
                <br />
                {/* <br /> */}
                2. Using NLP techniques, we provided them with text data opinion
                mining facility and sentiment analysis for their social media
                channels
                <br />
                {/* <br /> */}
                3. For each of their social media channel including Facebook,
                Twitter, and Youtube, they were provided with data collection,
                cleaning, visualization, classification and sentiment analysis
                of the comments section
                <br />
                <br />
                This helped them to streamline and use data to design their
                political campaign for the year effectively on the bases of
                authentic data.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  The Result
                </b>
                <br />
                <br />
                1. We designed a SaaS-based set-up pipeline for automated
                conversion of speech to text
                {/* <br /> */}
                <br />
                2. Using NLP techniques, we provided them with text data opinion
                mining facility and sentiment analysis for their social media
                channels
                {/* <br /> */}
                <br />
                3. For each of their social media channel including Facebook,
                Twitter, and You
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  KPI's Achievments
                </b>
                <br />
                <br />
                1. We designed a SaaS-based set-up pipeline for automated
                conversion of speech to text
                {/* <br /> */}
                <br />
                2. Using NLP techniques, we provided them with text data opinion
                mining facility and sentiment analysis for their social media
                channels
                {/* <br /> */}
                <br />
                3. For each of their social media channel including Facebook,
                Twitter, and You
              </p>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
