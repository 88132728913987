export const initializeGoogleAnalytics = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-TZ0M68EQR7';

  const body = document.getElementsByTagName('body')[0];
  body.insertAdjacentElement('beforeend', script);

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-TZ0M68EQR7');
  `;

  //   console.log("Code is reached here.")

  body.insertAdjacentElement('beforeend', inlineScript);
};