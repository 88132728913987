import React from "react";
import Navbar from "../../components/navbar";
import img1 from "../../assets/images/mysql.png";
import img2 from "../../assets/images/dataimg3.png";
import img3 from "../../assets/images/dataimg4.png";
import img4 from "../../assets/images/Cassandra.png";
import img5 from "../../assets/images/redis.png";
import bannar from "../../assets/images/Database management.svg";

import Footer from "../../components/footer";
import { useState } from "react";

export default function DataManagement() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}> Database Management</span>{" "}
                as service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                With the majority user share for mobile devices worldwide, it’s
                an important and powerful platform
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:px-20  px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 pl-12 md:pl-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-5">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Project42 Labs is always a step ahead in developing large
              databases with easy maintenance and enhanced security. We've
              mastered the development of databaseswith low repetition, low
              redundancy and data independence which increases the end-user
              productivity. We create well-structured database management
              systems with multi-user environment support and improved
              consistency to boost our client's business processes.{" "}
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Database Management
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4 md:ml-12 ml-6">
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                The cutting edge of data
              </b>{" "}
              <br />
              <br />
              Decent data management ultimately impacts the bottom line of
              business by increasing productivity, accuracy and reliability.
              It's a critical tool in analysis and decision making to optimize
              operations and provide a competitive edge to firms.
              <br />
              <br />
              At Project42 Labs, we create the most optimized database
              management systems by analyzing the nature of applications and
              breaking data into logical pieces. The data warehouses we build
              are proficient to easily gather and analyze all kinds of data from
              various data sources. They're subject-oriented, time-variant and
              can maintain data integrity.
              <br />
              <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                The valuable role of ETL
              </b>
              <br />
              <br />
              We use ETL (Extract, Transform, Load) tools to amass, read and
              migrate vast volumes of raw data from numerous sources. Later they
              load all these data into a database warehouse for ease of access.
              Operational resilience, augmented data cleansing and amplified
              business intelligence are some key windfalls of ETL tools.
              <br />
              <br /> <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Security first
              </b>{" "}
              <br />
              <br />
              Data security is crucial when it comes to preventing block attacks
              comprising ransomware and exploitation of data. Project42 Labs
              doesn't compromise the security of sensitive information of our
              clients and follows strict measures to deter data from being
              abused. We use database firewalls, encrypt data and audit and
              monitor database activity to enhance database security
              <br /> <br />
              <br />
            </p>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                Technology Focus
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 w-full px-6">
          <div className="flex w-full items-center">
            <div className="flex overflow-x-scroll scrollbar-hide  mx-[auto]">
              <div class=" flex w-full md:gap-0 gap-5  justify-between flex-row items-center">
                <div className="w-[120px] h-[30%] flex flex-col justify-center items-center">
                  <img src={img1} alt="" />
                </div>
                <div className="w-[120px] h-[30%] flex flex-col justify-center items-center">
                  <img src={img2} alt="" />
                </div>
                <div className="w-[120px] h-[30%] flex flex-col justify-center items-center">
                  <img src={img3} alt="" />
                </div>
                <div className="w-[120px] h-[30%] flex flex-col justify-center items-center">
                  <img src={img4} alt="" />
                </div>
                <div className="w-[120px] h-[30%] flex flex-col justify-center items-center">
                  <img src={img5} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Use Cases for Databases
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 pb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Databases are the foundation of any high-performance software
              product/ application. A great database architecture serves the
              same purpose as a blueprint for building under/ after
              construction.
              <br />
              <br />
              We routinely perform AMCs for database maintenance, migration from
              one (legacy) database architecture to another (modern), migration
              of on-prem databases to on-cloud databases.
              <br />
              <br />
              We design and build our databases that check for:
              <br />
              <br />
              <ul className=" pl-5 list-disc ">
                <li>Consistency: Refers to uniformity in architecture.</li>
                
                <li>
                  Cascading: Maintain a parent-child relationship to maintain
                  data hierarchy and prevent repetition.
                </li>
                <li>
                  Redundancy: We normalize our databases to reduce database
                  size. This allows complex databases to have a smaller
                  footprint and also avoids duplication or repetition of data.
                </li>
                <li>
                  Queries: Simple and fast queries that solve for time and
                  computational complexity.
                </li>
                <li>
                  Performance: Database is only built once the design solves
                  for time and computational complexity.
                </li>
                <li>
                  Maintenance: Well-designed databases are easier and more
                  efficient to manage
                </li>
              </ul>
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
