import React from "react";
import Navbar from "../../components/navbar";
import img1 from "../../assets/images/Best practices.svg";
import img2 from "../../assets/images/homeimg2.png";
import img3 from "../../assets/images/homeimg3.png";
import logo1 from "../../assets/images/logo2.png";
import logo2 from "../../assets/images/logo3.png";
import Footer from "../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";
import { useNavigate } from "react-router-dom";

const BestPractices = () => {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>
                  Onboarding and Best Practices
                </span>
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                We're the dreamers, the builders & the doers
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={img1}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              We are a service company and understand that you trust us with
              your ideas and milestones. So the responsibility of us becomes
              greater to become your partners and work towards the common goal.
              <br />
              We trust our biggest advantage of being our entrepreneurial minds,
              we become as excited about your products as you. While working
              with big manufacturing setups, or a first-time entrepreneur, we
              ensure that your technology challenge is covered with us with the
              process of education, onboarding, working together and opening up
              your project to your wider audience.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                The Green Room Check{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              This is an important step to understand and evaluate the client's
              historical and prevailing systems, both successful and failed
              endeavors, and business goals.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Crafting the project plan
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Addressing the problems suitably and comprehending what end
              objectives are and in order to achieve it is our first stride in
              creating a plan. While looking out for the voids and setting
              expectations, we make sure that we perfectly align our integral
              skills in tune with the problem at hand.
              <br />
              Since this is the road to strengthening relationships, we address
              the course of mutual transparency here.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Our skilled squad
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              At this stage, we pick the team for you that aligns with skillset
              and industry experience.
              <br />
              For any client, our standard squad consists of a project manager,
              technical architect, developers, and supervisor. We give them an
              overview of the client and assign responsibilities to the members.
              <br />
              These details are shared with clients as well.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Communication is the key
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              Both external and internal communications play pivotal in
              educating on the client's objectives, industry, services, and the
              implication of our work. We have our standard weekly, fortnightly
              updates, and reviews.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Time for the kickoff call{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              This is the step we take very seriously, to create lasting
              impressions. From the basic introduction to discussing milestones,
              goals, and project delivery.
              <br />A longer than usual, we ensure this one gets on the right
              foot and make everyone excited about the goals we have set out to
              achieve ahead.
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                The checkup call{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              This is a process that happens, every fortnight from our
              supervisor. A one-on-one tool, it's for pouring your heart out and
              cross-checking the timelines and delivery on both the ends. A
              quick/detailed chit chat to doubly ensures synchronization from
              every stakeholder.<br/><br/><br/><br/>
            </p>
            {/* </div> */}
          </div>
        </div>

        
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default BestPractices;
