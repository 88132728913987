import React from "react";
import Navbar from "../../components/navbar";
// import img1 from "../../assets/images/.png";

// import img2 from "../../../assets/images/homeimg2.png";
import img3 from "../../assets/images/homeimg3.png";
// import logo1 from "../../../assets/images/logo2.png";
// import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";
import { useNavigate } from "react-router-dom";
import caseimg1 from "../../assets/images/case-study-1.png";
import caseimg2 from "../../assets/images/case-study-2.png";
import caseimg3 from "../../assets/images/case-study-3.png";
import caseimg4 from "../../assets/images/case-study-4.png";
import caseimg5 from "../../assets/images/case-study-5.png";
import caseimg6 from "../../assets/images/case-study-6.png";
import caseimg7 from "../../assets/images/caseStudy7.png";
import bannar from "../../assets/images/Machine Learning.svg";

export default function Work() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };
  const Navigate = useNavigate();
  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>Our Work</span>
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Stories of building disruptive & exciting products
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20 px-6 ">
          {/* <hr className="h-[2px] mt-6" /> */}
          {/* <div className="md:flex justify-between items-center md:my-[50px] my-10">
            <div className=" md:w-[40%]">
              <h3 className="text-[#FFFFFF] md:text-[40px] font-san text-[14px] font-[500] text-left">
                Introduction
              </h3>
            </div>
            <div className="md:w-[60%] mt-4">
              <p className="md:text-[26px] text-[12px] tracking-wide font-noto font-[400] text-[#FFFFFF]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque velit tellus, efficitur sed sodales quis, cursus
                eget nulla. Curabitur eu ex ut ex lacinia consectetur. Aliquam
                elementum quis enim et finibus. Curabitur dapibus blandit orci,
                vulputate elementum dui sagittis eu. Duis felis velit, viverra
                sed faucibus sed, blandit eget diam. In non lectus nisl. Donec
                luctus ex ut arcu bibendum, a hendrerit nisi porttitor. Quisque
                quis feugiat risus, ut gravida enim. Aenean vitae tellus non
                ligula tincidunt porttitor.
              </p>
            </div>
          </div>
          <hr className="h-[2px]" /> */}

          <div className="md:flex-row  md:pb-0 flex-col items-start gap-6 justify-between md:mt-[100px] mt-10 md:pr-3">
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] font-san font-medium text-left md:pl-20">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] "></b>
              Our case studies
            </h3>
            <div className="pb-6 md:pb-8 md:flex-none flex w-full flex-col md:gap-0 items-center gap-6">
              <div className="w-full mt-8 md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2 md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg5}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Building a ePublisher and eDistribution platform
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-4 mt-2">
                          Mon, Nov 27, 2023
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          The client faced several challenges in building their
                          ePublisher and eDistribution platform.
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-5")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
              <div className="w-full   md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2 md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg6}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Automated HRMS for blue collar workers
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-8 mt-2">
                          Fri, Dec 15, 2023
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          The existing recruitment process relied heavily on
                          manual paperwork and spreadsheets, leading to
                          inefficiencies, errors, and delays. They needed an
                          automated system to streamline and expedite the hiring
                          process.
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-6")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
              <div className="w-full  md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2  md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg1}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Sentiment Analysis for a Political Think Tank
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-8 mt-2">
                          Wed, Oct 18, 2023
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          This time, we were approached by an autonomous
                          political think tank of the country to create models
                          for sentiment analysis.
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-1")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
              <div className="w-full  md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2 md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg2}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Data collection & cleaning
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-8 mt-2">
                          Mon, Jan 22, 2024
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          The aim behind this project was to gather thousands of
                          data, clean and present in an actionable format to a
                          young startup.{" "}
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-2")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
              <div className="w-full  md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2 md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg3}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Simplifying order management systems
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-8 mt-2">
                          Mon, Mar 4, 2024
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          Centralizing the order management system of a legacy
                          company by consolidating pan India orders from chain
                          of multiple vendors{" "}
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-3")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
              <div className="w-full  md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2 md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg4}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Data-Driven Market Research & RPA
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-8 mt-2">
                          Fri, April 26, 2024
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          Launching e-commerce business for leading
                          manufacturing{" "}
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-4")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
              <div className="w-full  md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 h-fit pb-3 justify-between">
                  <Hover scale={1.05} perspective={2000} speed={500}>
                    <div className="rounded-[20px] py-3 md:flex md:flex-col-2 md:w-[80%] md:h-[250px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 md:mb-10  group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                      <div className="md:w-[30%] md:m-[auto]">
                        <img
                          src={caseimg7}
                          className="rounded-[15px] md:h-[210px] md:w-full"
                        ></img>
                      </div>
                      <div className="md:w-[70%] md:ml-5">
                        <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-white font-medium md:mt-4 mt-2">
                          Simplifying bi-focal lens manufacturing with AR 
                        </h3>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-1 md:mb-8 mt-2">
                          Mon, May 20, 2024
                        </p>
                        <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-3 mt-2">
                          The challenge
                        </p>
                        <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 mt-2 inline">
                          Very expensive and proprietary machinery and software {" "}
                        </p>
                        <button
                          onClick={() => Navigate("/company/work/case-study-7")}
                          className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                        >
                          &nbsp;Read more...
                        </button>
                      </div>

                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
