import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import img2 from "../../../assets/images/homeimg2.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";
import blogimg2 from "../../../assets/images/blog2.png";

export default function Blog2() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#1B2430] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
        <div className="w-full md:h-[580px] h-[242px] flex flex-row justify-between items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px] md:w-[50%]  w-[60%]">
              <h3 className="text-[#A71AFB] md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto  md:pl-20">
                Smart Manufacturing Technology during COVID-19; A step towards
                future
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] font-noto text-[14px] font-[400] text-left md:mt-12 mt-4 md:pl-20">
                By : Surya Narayan Dev
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] font-noto text-[12px] font-[400] text-left md:mt-12 mt-4 md:pl-20">
                Friday, Dec 11, 2020
              </h3>
            </div>
            <div className="w-2/5 md:w-1/2">
              <img
                src={blogimg2}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
            {/* <div className="z-10 w-[700px] h-[600px]" style={style}>
          <div className="bg-machine h-[579px] w-[630px] z-20 " >
            <img
              src={img1}
              alt=""
              className="md:w-full w-[210px] h-[180px] md:h-full"
            />
            
            </div>
            </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 ">
          {/* // <hr className="h-[2px] mt-6" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="w-full md:w-[80%]">
              {/* <h2 className="text-[#FFFFFF] md:text-[40px] font-san text-[14px] font-[400]">
                What can we do ?
              </h2> */}
              <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[16px] font-[300] md:mt-8 md:mr-[60px] mt-4">
                
                {/* <span className="bg-[#a71afb] md:w-[2px] md:h-full"></span> */}
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                  Smart Manufacturing Technology during Covid-19; A step towards
                  future
                </b>{" "}
                <br />
                <br />
                Along with the globe, the manufacturing industry is also
                surviving through the effects caused by the Covid-19 pandemic.
                An estimated 64% of the industry was affected by the lockdown as
                40-50% of the workforce was unavailable to execute tasks
                on-site. A survey conducted by the National Association of
                Manufacturers found out that the country's industrial growth
                will hit a 30 year low of 2% in the financial year 2020-21. Now
                that the operations are slowly commencing again, the health of
                the workforce has become the priority of manufacturers across
                the country, and also the government. To cope up with decreased
                worker density, manufacturers have started deploying automation
                and smart factory technologies. These days have proven as a
                critical time to explore smart manufacturing strategies and
                bring automation onto the factory floor.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">Social distancing in the workplace</b> Following the social
                distancing norms in the workplace has become a preference for
                both labourers and manufacturers. AI aided technology can be a
                huge help in keeping the minimum distance between workers and
                preventing overcrowding. Hands-free door openers such as
                foot-operated door knobs will aid in reducing contact with
                surfaces. AI-enabled workplace monitoring system which can be
                integrated into CCTV cameras will give an alert when the
                distance between workers exceeds six feet. Workplace safety
                wearables can help in contact tracing. In case a worker gets
                infected, their location data history can be collected through
                the device and can identify the people who have been in contact
                with the infected person. Automation of repetitive tasks and
                deploying autonomous devices will enable firms to withstand
                lower employee density.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]">AI in supply chain</b> Using IoT & AI for predictive
                maintenance will help in meeting the changing demands and
                circumstances in the supply chain and factories. Real-time
                analysis of the supply chain will recognize weak links in the
                network and identify alternative suppliers to take action
                accordingly. Big data analytics will reshape the future supply
                chain and will turn it from forecast-driven to demand-driven.
                This will be a huge advantage in anticipating the future market
                and moving according to customer demands. Data science
                algorithms will make customer segmentation and clustering easier
                by integrating data from customer actions and product reviews.
                <br />
                <br />
                <b className="border-l-[5px] pl-3 border-[#A71AFB]"> Surveillance during social distancing</b> Overall
                administrative chores in the factory can be done by integrating
                them into several schemes such as plant asset management,
                warehouse management system, and machine condition monitoring.
                Live status of machinery can be made available on dashboards and
                supervisors can manage operations in that manner to minimize
                unnecessary walkabouts. Quality inspection can be done through
                image analytics, instead of visiting the factory ground
                directly. 3D cameras which can count the number of people
                present in the workplace will give alerts to the manager via
                email or text messages to prevent overcrowding. Employees can be
                supervised through thermal cameras which can check their body
                temperature while they're in the workplace. These strategies,
                which will increase efficiency, reduce: risk, time and cost, are
                also going to create a new normal in the future manufacturing
                industry after the pandemic. Smart manufacturing industry 4.0,
                as the outcome of the fourth industrial revolution, will pave
                the way for long-term changes in the whole industry.
              </p>
            </div>
            <div className="w-full md:w-[20%] " >
              <div className="w-full mt-[0px] md:mt-0">
                <div className="  md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 ">
                  <Hover scale={1.05} perspective={700} speed={500}>
                    <div className="md:w-[240px]  md:h-[607px] md:px-4 px-2 md:py-4 md:mt-6 mb-10 mt-4 group md:hover:bg-white md:hover:shadow-white md:hover:shadow-sm md:hover:scale-105 ">
                      <img src={img3} className="rounded-[100%] "></img>

                      <h3 className="text-[#FFFFFF] md:text-[18px] text-center md:text-left font-san text-[16px] md:group-hover:text-black font-medium md:mt-4 mt-2">
                        About the author
                      </h3>
                      <p className="text-[#FFFFFF] md:text-[16px] text-center md:text-left font-noto text-[14px] md:group-hover:text-black font-[400] md:mt-3 mt-2">
                        Surya is the Founder & CTO of Project42 Labs. A King’s
                        College London alumnus, he has over 9 years of
                        experience in Data Science & Machine Learning and
                        building end-to-end solutions to meet business goals.
                        He’s usually found playing sports, reading and listening
                        to music when not working.
                      </p>
                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
            </div>
          </div>

          {/* //<hr className="mt-14" />  */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
