import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import bannar from "../../../assets/images/caseStudy7.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
// import Hover from "react-3d-hover";

export default function CaseStudy7() {
    const [modals, setModals] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [readMore, setReadMore] = useState({
        keytakeaway: false,
        client: false,
        challenges: false,
        solution: false,
        result: false,
    });

    const style = {
        background:
            "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
    };

    const clickHandler = () => {
        setModal2(true);
        console.log("clicked");
    };

    return (
        <>
            <Navbar setModals={setModals} modals={modals} />
            <div style={style} className="bg-[#27232c] md:pb-10 ">
                <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[475px] h-[240px] ">
                    <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
                        <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
                            <h3 className="text-white md:text-[40px] text-[14px] font-Philosopher font-medium text-left   ">
                                <span style={{ color: "#A71AFB" }}>
                                    Simplifying bi-focal lens manufacturing with AR 
                                </span>
                            </h3>

                            <h3 className="text-[#FFFFFF] tracking-widest md:text-[20px]   text-[12px] font-[400] text-left md:mt-4 mt-4 ">
                                Mon, May 20, 2024
                            </h3>
                            {/* <h3 className="text-[#A71AFB] tracking-widest md:text-[28px]   text-[12px] font-[400] text-left md:mt-12 mt-4 ">
                Summary
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px]   text-[10px] font-[400] text-left md:mt-4 mt-4 ">
                The aim behind this project was to gather thousands of data,
                clean and present in an actionable format to a young startup.
              </h3> */}
                        </div>
                        <div className="w-1/2">
                            <img
                                src={bannar}
                                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="md:pl-20 px-6 pb-10  ">
                    {/* <hr className="h-[2px] mt-6" /> */}

                    <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
                        <div className="md:w-[100%]">
                            <p className="text-[#FFFFFF] md:text-[20px]   text-[12px] font-[300] md:mt-8 mt-4">
                                {/* <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                                    Summary :{" "}
                                </b>
                                The aim behind this project was to gather thousands of data,
                                clean and present in an actionable format to a young startup.
                                <br />
                                <br /> */}
                                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                                    The client:{" "}
                                </b>
                                A 60+ Years old leading lens manufacturer in India.  
                                <br />
                                <br />
                                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                                    The Work:{" "}
                                </b>
                                Understanding the workbench (Flat surface) and relevant tools for bi-focal lens manufacturing. The product entailed motion tracking and a data ingestion pipeline to build virtual assets. 
                                <br />
                                <br />
                                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                                    The Challenge{" "}
                                </b>
                                <br />
                                <br />
                                {/* <br /> */}
                                1. Very expensive and proprietary machinery and software.
                                <br />
                                {/* <br /> */}
                                2. Skilled labour.
                                <br />
                                {/* <br /> */}
                                3. Expensive maintenance of both machinery and labour.
                                <br />{" "}
                                {readMore.challenges && (
                                    <>
                                        <br />
                                        With these individual elements in place, the process is still prone to errors during the manufacturing process.
                                        <br />  
                                        <br />
                                        Each error results in:
                                        <br />
                                        1.Wasted raw-material.
                                        <br />
                                        2.Wasted man-hours.
                                        <br />
                                        3.Delayed manufacturing line.
                                        <br />
                                        4.Delayed shipment of final order to the end-client.
                                        <br />
                                        5.Increased cost of manufacturing and lower bottom-line.
                                    </>
                                )}
                                <button
                                    onClick={() =>
                                        setReadMore((prev) => ({
                                            ...prev,
                                            challenges: !readMore.challenges,
                                        }))
                                    }
                                    className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                                >
                                    &nbsp;{readMore.challenges ? "Read less" : "Read more..."}
                                </button>
                                <br />
                                <br />
                                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                                    The P42L Solution:{" "}
                                </b>
                                We started by a visit to the client’s factory and understanding the  manufacturing process of bifocal and progressive lenses. The unique problem required  a unique solution in an AR Application. 
                                <br />
                                <br />
                                The AR App intended to: 
                                <br />
                                <br />
                                1. Reduce dependency or entirely replace:
                                <br />
                                a. Expensive and proprietary machinery and software
                                <br />
                                b. Expensive and skilled labour
                                <br />
                                c. Expensive maintenance of both machinery and labour <br />  <br />
                                2. Increase speed of processing and manufacturing of lenses. 

                                {readMore.solution && (
                                    <>
                                        <br />
                                        <br />
                                        Result: P42L developed the AR Application in a span of 68 working days. The aim was to have an unskilled or moderately trained factory worker use it to manufacture a bifocal and progressive lens.  
                                        <br />
                                        <br />
                                        The software deployment saved:
                                        <br />
                                        1. Crore+ in buying and maintaining capable imported machinery and associated  software.
                                        <br />
                                        2. Man-hours and resources in employee training.  
                                        <br />
                                    </>
                                )}
                                <button
                                    onClick={() =>
                                        setReadMore((prev) => ({
                                            ...prev,
                                            solution: !readMore.solution,
                                        }))
                                    }
                                    className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                                >
                                    &nbsp;{readMore.solution ? "Read less" : "Read more..."}
                                </button>
                                <br />
                                <br />
                                <b className="border-l-[5px] pl-3 border-[#A71AFB]">
                                    Key Takeaways
                                </b>
                                <br />
                                <br />
                                - Redeemed labor & resources  
                                <br />
                                <br />
                                The time and resources required for employee training were saved. The speed of the  manufacturing of lenses was increased. Reducing errors during manufacturing helped in  saving raw materials.  

                                {readMore.keytakeaway && (<>
                                    <br />
                                    <br />
                                    - Cost reduction
                                    <br />
                                    <br />
                                    By reducing dependency on expensive machinery, software and their maintenance, a  considerable amount of labor and cost were saved. Eliminating the delay in  manufacturing could also save costs.  
                                    <br />
                                    <br />
                                    - Less dependency on skilled labor  
                                    <br />
                                    <br />
                                    Unskilled factory workers utilize the app in manufacturing processes resulting in  reducing the OPEX (Operational Expenditure).  </>)}
                                <button
                                    onClick={() =>
                                        setReadMore((prev) => ({
                                            ...prev,
                                            keytakeaway: !readMore.keytakeaway,
                                        }))
                                    }
                                    className="  md:text-[15px] text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2 md:ml-[2px] inline"
                                >
                                    &nbsp;{readMore.keytakeaway ? "Read less" : "Read more..."}
                                </button>
                            </p>
                        </div>
                    </div>

                    {/* <hr className="mt-14" /> */}
                </div>
            </div>

            <div
                className={`${modals ? "translate-y-[0px]" : "translate-y-[240px]"
                    } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
            >
                <div className="flex flex-row items-center justify-center">
                    <div
                        className="w-[48px] h-[3px] bg-[#5A5B5B]"
                        onClick={() => {
                            setModals(!modals);
                        }}
                    ></div>
                </div>
                <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
                    <div
                        className=""
                        onMouseOver={clickHandler}
                        onMouseOut={() => setModal2(false)}
                    >
                        {" "}
                        <span>Services</span>
                        <div
                            className={`${modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
                                } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
                        >
                            <ul className="text-white">
                                <li>Web Development</li>
                                <li className="mt-4">Data Science</li>
                                <li className="mt-4">Machine Learning</li>
                            </ul>
                        </div>
                    </div>
                    <li className="mt-4">Solutions</li>
                    <li className="mt-4">Work</li>
                    <li className="mt-4">Company</li>
                    <li className="mt-4">Contact Us</li>
                </ul>
            </div>

            <Footer />
        </>
    );
}
