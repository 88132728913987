import React from "react";
import Navbar from "../../../components/navbar";
// import img1 from "../../assets/images/.png";

import img2 from "../../../assets/images/homeimg2.png";
import img3 from "../../../assets/images/homeimg3.png";
import logo1 from "../../../assets/images/logo2.png";
import logo2 from "../../../assets/images/logo3.png";
import Footer from "../../../components/footer";
import { useState } from "react";
import Hover from "react-3d-hover";
import blogimg5 from "../../../assets/images/blog1.png";

export default function Blog5() {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
      <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-6 md:pl-0 md:pr-[60px]  w-[50%]">
              <h3 className="text-[#A71AFB] md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-20">
                Smart Manufacturing Technology during COVID-19; A step towards
                future
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] font-noto text-[14px] font-[400] text-left md:mt-12 mt-4 pl-20">
                By : Surya Narayan Dev
              </h3>
              <h3 className="text-[#FFFFFF] tracking-widest md:text-[26px] font-noto text-[12px] font-[400] text-left md:mt-12 mt-4 pl-20">
                Fri May 29 2020
              </h3>
            </div>
            <div className="md:w-1/2">
              <img
                src={blogimg5}
                className="rounded-[20px] border-[5px] border-[#A71AFB] md:mx-[auto] md: w-[70%] md:h-[70%]"
              ></img>
            </div>
            {/* <div className="z-10 w-[700px] h-[600px]" style={style}>
          <div className="bg-machine h-[579px] w-[630px] z-20 " >
            <img
              src={img1}
              alt=""
              className="md:w-full w-[210px] h-[180px] md:h-full"
            />
            
            </div>
            </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 ">
          {/* <hr className="h-[2px] mt-6" /> */}
          {/* <div className="md:flex justify-between items-center md:my-[50px] my-10">
            <div className=" md:w-[40%]">
              <h3 className="text-[#FFFFFF] md:text-[40px] font-san text-[14px] font-[500] text-left">
                Introduction
              </h3>
            </div>
            <div className="md:w-[60%] mt-4">
              <p className="md:text-[26px] text-[12px] tracking-wide font-noto font-[400] text-[#FFFFFF]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque velit tellus, efficitur sed sodales quis, cursus
                eget nulla. Curabitur eu ex ut ex lacinia consectetur. Aliquam
                elementum quis enim et finibus. Curabitur dapibus blandit orci,
                vulputate elementum dui sagittis eu. Duis felis velit, viverra
                sed faucibus sed, blandit eget diam. In non lectus nisl. Donec
                luctus ex ut arcu bibendum, a hendrerit nisi porttitor. Quisque
                quis feugiat risus, ut gravida enim. Aenean vitae tellus non
                ligula tincidunt porttitor.
              </p>
            </div>
          </div>
          <hr className="h-[2px]" /> */}

          <div className="md:flex flex-col md:flex-row justify-between md:mt-[100px] mt-10 pr-3">
            <div className="md:w-[80%]">
              {/* <h2 className="text-[#FFFFFF] md:text-[40px] font-san text-[14px] font-[400]">
                What can we do ?
              </h2> */}
              <p className="text-[#FFFFFF] md:text-[20px] font-noto text-[16px] font-[300] md:mt-8 md:mr-[60px] mt-4">
                Within a few weeks, the Coronavirus pandemic has spread panic
                across the world and shaken the confidence of enterprises. In
                this scenario, businesses are struggling the most due to market
                uncertainty, which will go along way. Enterprises are facing a
                number of challenges including operations, sales, customer
                management, and long-distance collaboration.
                <br />
                <br />
                The consequences of the pandemic made a shift in the current
                business operations and strategies, and that has resulted in a
                dire need for better technologies and digital transformation.
                Exposing your business to the AI tools of this new era is the
                need of the hour.
                <br />
                <br />
                Here are some basic present challenges faced by enterprises and
                ways to tackle them.
                <br />
                <br />
                Tapping on Man-Hours & Optimizing Production
                <br />
                <br />
                Through video surveillance and image recognition, error-free
                face recognition, person and object detection can be done.
                Another advantage is that the deep learning system behind these
                techniques can be trained and improved continuously, also
                contemplating the social distancing norms placed, which can be
                taken into account while formulating these solutions at this
                time.
                <br />
                <br />
                AI-based image recognition can be employed throughout the
                manufacturing process to control quality and identify faulty
                products. This can be a step in reducing waste and increasing
                production efficiency.
                <br />
                <br />
                Process management and improvement, and lack of connection
                between business processes are two of the most troublesome
                problems that companies are facing now. To confirm the
                investments towards improvements, understanding the current
                process is crucial. That's where process mining enters. Process
                mining enhances efficiency and understanding of processes. The
                software will help to collect data from enterprise transaction
                systems and provides information about the performance of key
                processes. Companies can discover the reasons for the time lag
                between the "lead to order" process and take measures to lessen
                sales process time through this.
                <br />
                <br />
                Process mining will also help in understanding the explanations
                behind late deliveries and correct them and run deliveries
                smoothly.
                <br />
                <br />
                It can help in accurately measuring the improvement after the
                company has made a change.
                <br />
                <br />
                Lack of Digital Record-Keeping
                <br />
                <br />
                During this period of isolation and remote working, there is an
                inevitable need of making the company records available to every
                stakeholder. Maintaining a digital filing system will help in
                easier retrieval of information, saving the cost of storage
                space through cloud storage solutions, and securing records of
                customer dealings. It’ll also enable long-distance collaboration
                and decrease the chances of losing data and human errors.
                <br />
                <br />
                Optical Character Recognition(OCR) is a method used to identify
                text inscribed in images, scanned documents, etc. OCR can be
                utilized to convert paper documents into machine-readable texts,
                virtually and store them. It is also used to scan vouchers and
                promotional codes.
                <br />
                <br />
                Interaction with Legacy Systems
                <br />
                <br />
                Managing queries, maintenance of records and transactions,
                calculation of sales, quality reporting, etc. can be get done
                through Robotic Process Automation (RPA). The software robots
                will automate repetitive tasks and manual processes through
                their AI and ML abilities, saving your employees from mundane
                work. This can save man-hours, and that work can be well
                utilized in productive tasks. They’ll ensure the implementation
                of regularities and standards and streamline your business
                process. This is a proven way of reducing time for basic tasks
                in the most affordable way.
                <br />
                <br />
                Effective Communication and Decision-making
                <br />
                <br />
                Enterprise Resource Planning(ERP) can be used to track and
                manage business processes with integrated systems. It’ll
                eliminate unnecessary operations and data, and provide reliable
                information. Facilitating sharing information between all units
                of the enterprise is another advantage. Users can also analyze
                real-time data and measure performances.
                <br />
                <br />
                During this period of remote working, ERP will ensure better
                inter-department communications. ERP is also used in controlling
                sales orders, tracking shipments, and integrating tax rules.
                <br />
                <br />
                Creation of Channels of E-commerce Transactions
                <br />
                <br />
                LiveWhatsAppp chatbots can offer a personalized experience for
                your e-commerce customers and ultimately influence their order
                taking and payment cycle. This will inspire small stores to rely
                more on technology by providing a complete ecosystem of
                transactions. Chatbots can also collect data from email
                marketing campaigns and measure their effectiveness.
                <br />
                <br />
                Project42 Labs focuses on assisting enterprises to expand
                through business intelligence technologies we offer. With the
                help of our professionals, we guarantee you time-bound delivery
                of services, flexibility in operations, and swift services on
                data. Through our actively updated tools, custom scripts, and
                error-free data collection, we’ll ensure your business never get
                behind in the technology game.
              </p>
            </div>
            <div>
              <div className="w-full mt-[0px] md:mt-0">
                <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 ">
                  <Hover scale={1.05} perspective={700} speed={500}>
                    <div className="md:w-[240px]  md:h-[607px] md:px-4 px-2 md:py-4 md:mt-6 mb-10 mt-[-100px] group hover:bg-white hover:shadow-white hover:shadow-sm hover:scale-105 ">
                      <img src={img3} className="rounded-[100%]"></img>

                      <h3 className="text-[#FFFFFF] md:text-[18px] font-san text-[16px] group-hover:text-black font-medium md:mt-4 mt-2">
                        About the author
                      </h3>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[14px] group-hover:text-black font-[400] md:mt-3 mt-2">
                        Surya is the Founder & CTO of Project42 Labs. A King’s
                        College London alumnus, he has over 9 years of
                        experience in Data Science & Machine Learning and
                        building end-to-end solutions to meet business goals.
                        He’s usually found playing sports, reading and listening
                        to music when not working.
                      </p>
                      {/* <button className="text-[#FFFFFF] md:text-[15px] group-hover:text-[#A71AFB] text-[12px] font-[400] md:mt-2 mt-2">
                        LEARN MORE
                      </button> */}
                    </div>
                  </Hover>
                </div>
              </div>
            </div>
          </div>

          {/* <hr className="mt-14" /> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
