import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import blogimg1 from "../../assets/images/blog1.png";
import blogimg2 from "../../assets/images/blog2.png";
import blogimg3 from "../../assets/images/blog3.png";
import blogimg4 from "../../assets/images/blog4.png";
import blogimg from "../../assets/images/Blogs.svg";
import { useState } from "react";
import Hover from "react-3d-hover";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [modals, setModals] = useState(false);
  const [modal2, setModal2] = useState(false);

  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  const clickHandler = () => {
    setModal2(true);
    console.log("clicked");
  };
  const navigate = useNavigate();
  const Navigate = useNavigate();
  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 h-fit ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>The P42L Blog</span>
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Stories of Us Building some interesting Stuffs
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={blogimg}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:h-[10px] md:m-10"></div>

        <div className="flex-col gap-3 flex justify-between items-center pb-4 md:pb-0 md:mt-[100px] mt-20  md:pl-0 px-6">
          {/* <div className="w-full mt-[0px] md:mt-0">
              <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 justify-between">
                <Hover scale={1.05} perspective={2000} speed={500}>
                  <div className="rounded-[20px] md:flex md:flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                    <div className="md:w-[30%] md:m-[auto]">
                      <img
                        src={blogimg1}
                        className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                      ></img>
                    </div>
                    <div className="md:w-[70%] md:ml-5">
                      <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-black font-medium md:mt-4 mt-2">
                        COVID19 & essential automation for every business!
                      </h3>
                      <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-1 md:mb-8 mt-2">
                        By: Surya Narayan Dev
                      </p>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-10 mt-2">
                        Fri May 29 2020 By: Surya Narayan Dev
                      </p>

                      <button
                        onClick={() => navigate("/company/blogs/blogid-1")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-2"
                      >
                        READ MORE
                      </button>
                    </div>

                  </div>
                </Hover>
              </div>
            </div> */}

          <div className="w-full h-fit mt-[0px] md:mt-0">
            <div className=" h-fit md:h-full md:w-full md:pr-8 md:pb-2  mx-[auto] justify-between">
              <Hover scale={1.05} perspective={2000} speed={500}>
                <div className="md:rounded-[20px] rounded-[10px] p-3 md:flex md:flex-row flex flex-col md:w-[80%] md:h-[220px] md:px-4  md:py-4 md:mx-[auto] md:mt-6 md:mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm group md:hover:scale-105 ">
                  <div className="md:w-[30%] mb-3 md:mb-0 m-[auto] w-full ">
                    <img
                      src={blogimg1}
                      className="md:rounded-[15px] h-[190px] w-full object-contain md:w-full"
                    ></img>
                  </div>
                  <div className="md:w-[70%] md:ml-5  ">
                    <h3 className="text-[#FFFFFF] flex gap-3 md:gap-0 md:flex-none md:text-[30px] font-san text-[15px] items-center group-hover:text-white font-medium md:mt-4 mt-0">
                      <span>
                        {" "}
                        COVID19 & essential automation for every business!
                      </span>
                      <button
                        onClick={() => navigate("/company/blogs/blogid-1")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] md:hidden text-[13px] font-[400] md:mt-1"
                      >
                        READ MORE
                      </button>
                    </h3>
                    <p className="text-[#A71AFB] md:text-[16px] font-noto text-[14px]  font-[400] md:mt-1 md:mb-2 md:mt-2">
                      By: Surya Narayan Dev
                    </p>
                    <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 md:mt-2">
                      Fri May 29 2020
                    </p>

                    <button
                      onClick={() => navigate("/company/blogs/blogid-1")}
                      className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </Hover>
            </div>
          </div>

          <div className="w-full h-fit mt-28 md:mt-0">
            <div className=" h-fit md:h-full md:w-full md:pr-8 md:pb-2  mx-[auto] justify-between">
              <Hover scale={1.05} perspective={2000} speed={500}>
                <div className="md:rounded-[20px] rounded-[10px] p-3 md:flex md:flex-row flex flex-col md:w-[80%] md:h-[220px] md:px-4  md:py-4 md:mx-[auto] md:mt-6 md:mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm group md:hover:scale-105 ">
                  <div className="md:w-[30%] mb-3 md:mb-0 m-[auto] w-full ">
                    <img
                      src={blogimg2}
                      className="md:rounded-[15px] h-[190px] w-full object-contain md:w-full"
                    ></img>
                  </div>
                  <div className="md:w-[70%] md:ml-5 ">
                    <h3 className="text-[#FFFFFF] flex gap-3 md:gap-0 md:flex-none md:text-[30px] font-san text-[15px] items-center group-hover:text-white font-medium md:mt-4 mt-0">
                      <span>
                        {" "}
                        Smart Manufacturing Technology during COVID-19; A step
                        towards future
                      </span>
                      <button
                        onClick={() => navigate("/company/blogs/blogid-2")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] md:hidden text-[13px] font-[400] md:mt-1"
                      >
                        READ MORE
                      </button>
                    </h3>
                    <p className="text-[#A71AFB] md:text-[16px] font-noto text-[14px] font-[400] md:mt-1 md:mb-2 md:mt-2">
                      By: Surya Narayan Dev
                    </p>
                    <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 md:mt-2">
                      Friday, Dec 11, 2020
                    </p>

                    <button
                      onClick={() => navigate("/company/blogs/blogid-2")}
                      className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </Hover>
            </div>
          </div>

          <div className="w-full h-fit mt-28 md:mt-0">
            <div className=" h-fit md:h-full md:w-full md:pr-8 md:pb-2  mx-[auto] justify-between">
              <Hover scale={1.05} perspective={2000} speed={500}>
                <div className="md:rounded-[20px] rounded-[10px] p-3 md:flex md:flex-row flex flex-col md:w-[80%] md:h-[220px] md:px-4  md:py-4 md:mx-[auto] md:mt-6 md:mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm group md:hover:scale-105 ">
                  <div className="md:w-[30%] mb-3 md:mb-0 m-[auto] w-full ">
                    <img
                      src={blogimg3}
                      className="md:rounded-[15px] h-[190px] w-full object-contain md:w-full"
                    ></img>
                  </div>
                  <div className="md:w-[70%] md:ml-5 ">
                    <h3 className="text-[#FFFFFF] flex gap-3 justify-between md:gap-0 md:flex-none md:text-[30px] font-san text-[15px] items-center group-hover:text-white font-medium md:mt-4 mt-0">
                      <span> AI across Industries</span>
                      <button
                        onClick={() => navigate("/company/blogs/blogid-3")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] md:hidden text-[13px] font-[400] md:mt-1"
                      >
                        READ MORE
                      </button>
                    </h3>
                    <p className="text-[#A71AFB] md:text-[16px] font-noto text-[14px]  font-[400] md:mt-1 md:mb-2 md:mt-2">
                      By: Surya Narayan Dev
                    </p>
                    <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 md:mt-2">
                      Thur, April 11, 2024
                    </p>

                    <button
                      onClick={() => navigate("/company/blogs/blogid-3")}
                      className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </Hover>
            </div>
          </div>

          <div className="w-full h-fit mt-28 md:mt-0">
            <div className=" h-fit md:h-full md:w-full md:pr-8 md:pb-2  mx-[auto] justify-between">
              <Hover scale={1.05} perspective={2000} speed={500}>
                <div className="md:rounded-[20px] rounded-[10px] p-3 md:flex md:flex-row flex flex-col md:w-[80%] md:h-[220px] md:px-4  md:py-4 md:mx-[auto] md:mt-6 md:mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm group md:hover:scale-105 ">
                  <div className="md:w-[30%] mb-3 md:mb-0 m-[auto] w-full ">
                    <img
                      src={blogimg4}
                      className="md:rounded-[15px] h-[190px] w-full object-contain md:w-full"
                    ></img>
                  </div>
                  <div className="md:w-[70%] md:ml-5 ">
                    <h3 className="text-[#FFFFFF] flex gap-3 justify-between md:gap-0 md:flex-none md:text-[30px] font-san text-[15px] items-center group-hover:text-white font-medium md:mt-4 mt-0">
                      <span> Enigma of Alan Turing</span>
                      <button
                        onClick={() => navigate("/company/blogs/blogid-4")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] md:hidden text-[13px] font-[400] md:mt-1"
                      >
                        READ MORE
                      </button>
                    </h3>
                    <p className="text-[#A71AFB] md:text-[16px] font-noto text-[14px] font-[400] md:mt-1 md:mb-2 md:mt-2">
                      By: Surya Narayan Dev
                    </p>
                    <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-white font-[400] md:mt-0 md:mt-2">
                      Wed, May 29, 2024
                    </p>

                    <button
                      onClick={() => navigate("/company/blogs/blogid-4")}
                      className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </Hover>
            </div>
          </div>
          {/* <div className="w-full md:block hidden mt-[0px] md:mt-0">
              <div className=" h-[30px] md:h-full md:w-full md:pr-8 md:pb-2 pr-8 pb-2 mx-[auto] justify-between">
                <Hover scale={1.05} perspective={2000} speed={500}>
                  <div className="rounded-[20px] md:flex md:flex-col-2 flex flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                    <div className="md:w-[30%] m-[auto] w-[150px] ">
                      <img
                        src={blogimg2}
                        className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                      ></img>
                    </div>
                    <div className="md:w-[70%] md:ml-5 ml-3">
                      <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[12px] group-hover:text-black font-medium md:mt-4 mt-0">
                        Smart Manufacturing Technology during COVID-19; A step
                        towards future
                      </h3>
                      <p className="text-[#A71AFB] md:text-[16px] font-noto text-[10px] group-hover:text-black font-[400] md:mt-1 md:mb-2 md:mt-2">
                        By: Surya Narayan Dev
                      </p>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[8px] group-hover:text-black font-[400] md:mt-0 md:mt-2">
                        Friday, Dec 11, 2020
                      </p>

                      <button
                        onClick={() => navigate("/company/blogs/blogid-2")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                      >
                        READ MORE
                      </button>
                    </div>
                  </div>
                </Hover>
              </div>
            </div> */}

          {/* <div className="md:block hidden w-full mt-[0px] md:mt-0">
            <div className=" h-[30px] md:h-full md:w-full md:pr-8 md:pb-2 pr-8 pb-2 mx-[auto] justify-between">
              <Hover scale={1.05} perspective={2000} speed={500}>
                <div className="rounded-[20px] md:flex md:flex-col-2 flex flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                  <div className="md:w-[30%] m-[auto] w-[150px] ">
                    <img
                      src={blogimg3}
                      className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                    ></img>
                  </div>
                  <div className="md:w-[70%] md:ml-5 ml-3">
                    <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[12px] group-hover:text-black font-medium md:mt-4 mt-0">
                      AI across Industries
                    </h3>
                    <p className="text-[#A71AFB] md:text-[16px] font-noto text-[10px] group-hover:text-black font-[400] md:mt-1 md:mb-2 md:mt-2">
                      By: Surya Narayan Dev
                    </p>
                    <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[8px] group-hover:text-black font-[400] md:mt-0 md:mt-2">
                      Wednesday, Mar 04, 2020
                    </p>

                    <button
                      onClick={() => navigate("/company/blogs/blogid-3")}
                      className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </Hover>
            </div>
          </div> */}

          {/* <div className="md:block hidden w-full mt-[0px] md:mt-0">
            <div className=" h-[30px] md:h-full md:w-full md:pr-8 md:pb-2 pr-8 pb-2 mx-[auto] justify-between">
              <Hover scale={1.05} perspective={2000} speed={500}>
                <div className="rounded-[20px] md:flex md:flex-col-2 flex flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                  <div className="md:w-[30%] m-[auto] w-[150px] ">
                    <img
                      src={blogimg4}
                      className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                    ></img>
                  </div>
                  <div className="md:w-[70%] md:ml-5 ml-3">
                    <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[12px] group-hover:text-black font-medium md:mt-4 mt-0">
                      Enigma of Alan Turing
                    </h3>
                    <p className="text-[#A71AFB] md:text-[16px] font-noto text-[10px] group-hover:text-black font-[400] md:mt-1 md:mb-2 md:mt-2">
                      By: Surya Narayan Dev
                    </p>
                    <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[8px] group-hover:text-black font-[400] md:mt-0 md:mt-2">
                      Wednesday, Mar 04, 2020
                    </p>

                    <button
                      onClick={() => navigate("/company/blogs/blogid-4")}
                      className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-1"
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </Hover>
            </div>
          </div> */}

          {/* <div className="w-full mt-[0px] md:mt-0">
              <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 justify-between">
                <Hover scale={1.05} perspective={2000} speed={500}>
                  <div className="rounded-[20px] md:flex md:flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                    <div className="md:w-[30%] md:m-[auto]">
                      <img
                        src={blogimg3}
                        className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                      ></img>
                    </div>
                    <div className="md:w-[70%] md:ml-5">
                      <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-black font-medium md:mt-4 mt-2">
                        AI across Industries
                      </h3>
                      <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-1 md:mb-8 mt-2">
                        By: Surya Narayan Dev
                      </p>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-10 mt-2">
                        Wednesday, Mar 04, 2020
                      </p>

                      <button
                        onClick={() => navigate("/company/blogs/blogid-3")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-2"
                      >
                        READ MORE
                      </button>
                    </div>

                    
                  </div>
                </Hover>
              </div>
            </div>
            <div className="w-full mt-[0px] md:mt-0">
              <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 justify-between">
                <Hover scale={1.05} perspective={2000} speed={500}>
                  <div className="rounded-[20px] md:flex md:flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                    <div className="md:w-[30%] md:m-[auto]">
                      <img
                        src={blogimg4}
                        className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                      ></img>
                    </div>
                    <div className="md:w-[70%] md:ml-5">
                      <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-black font-medium md:mt-4 mt-2">
                        Enigma of Alan Turing
                      </h3>
                      <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-1 md:mb-8 mt-2">
                        By: Surya Narayan Dev
                      </p>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-10 mt-2">
                        Wednesday, Mar 04, 2020
                      </p>

                      <button
                        onClick={() => navigate("/company/blogs/blogid-4")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-2"
                      >
                        READ MORE
                      </button>
                    </div>

                   
                  </div>
                </Hover>
              </div>
            </div>
            <div className="w-full mt-[0px] md:mt-0">
              <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 justify-between">
                <Hover scale={1.05} perspective={2000} speed={500}>
                  <div className="rounded-[20px] md:flex md:flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                    <div className="md:w-[30%] md:m-[auto]">
                      <img
                        src={blogimg1}
                        className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                      ></img>
                    </div>
                    <div className="md:w-[70%] md:ml-5">
                      <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-black font-medium md:mt-4 mt-2">
                        COVID19 & essential automation for every business!
                      </h3>
                      <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-1 md:mb-8 mt-2">
                        By: Surya Narayan Dev
                      </p>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-10 mt-2">
                        Fri May 29 2020 By: Surya Narayan Dev
                      </p>

                      <button
                        onClick={() => navigate("/company/blogs/blogid-1")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-2"
                      >
                        READ MORE
                      </button>
                    </div>

                    
                  </div>
                </Hover>
              </div>
            </div>
            <div className="w-full mt-[0px] md:mt-0">
              <div className=" h-[130px] md:h-full w-full md:pr-8 md:pb-2 pr-3 pb-3 justify-between">
                <Hover scale={1.05} perspective={2000} speed={500}>
                  <div className="rounded-[20px] md:flex md:flex-col-2 md:w-[80%] md:h-[220px] md:px-4 px-2 md:py-4 md:mx-[auto] md:mt-6 mb-10 mt-[-100px] group hover:border-[#FE00E5] hover:border-[1px] hover:border-solid  hover:bg-gradient-to-r hover:from-[#FE00E5] hover:to-[#A61AFC] hover:shadow-black hover:bg-[#27232C] hover:shadow-sm hover:scale-105 ">
                    <div className="md:w-[30%] md:m-[auto]">
                      <img
                        src={blogimg1}
                        className="rounded-[15px] md:h-[190px] w-full object-contain md:w-full"
                      ></img>
                    </div>
                    <div className="md:w-[70%] md:ml-5">
                      <h3 className="text-[#FFFFFF] md:text-[30px] font-san text-[14px] group-hover:text-black font-medium md:mt-4 mt-2">
                        COVID19 & essential automation for every business!
                      </h3>
                      <p className="text-[#A71AFB] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-1 md:mb-8 mt-2">
                        By: Surya Narayan Dev
                      </p>
                      <p className="text-[#FFFFFF] md:text-[16px] font-noto text-[12px] group-hover:text-black font-[400] md:mt-10 mt-2">
                        Fri May 29 2020 By: Surya Narayan Dev
                      </p>

                      <button
                        onClick={() => navigate("/company/blogs/blogid-1")}
                        className="text-[#FFFFFF] group-hover:text-[#A71AFB] hidden md:block md:text-[15px] font-[400] md:mt-2"
                      >
                        READ MORE
                      </button>
                    </div>

                  </div>
                </Hover>
              </div>
            </div> */}
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 `}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12 mx-9">
          <div
            className=""
            onMouseOver={clickHandler}
            onMouseOut={() => setModal2(false)}
          >
            {" "}
            <span>Services</span>
            <div
              className={`${
                modal2 ? "translate-y-[0px]" : "translate-y-[200px]"
              } md:hidden w-full transition-all ease-in-out duration-500 bg-[#252525] fixed  -mx-9 px-9 py-5`}
            >
              <ul className="text-white">
                <li>Web Development</li>
                <li className="mt-4">Data Science</li>
                <li className="mt-4">Machine Learning</li>
              </ul>
            </div>
          </div>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default Blogs;
