import React from "react";
import Navbar from "../../components/navbar";
import bannar from "../../assets/images/Software development.svg";

import Footer from "../../components/footer";
import { useState } from "react";

export default function SoftwareDevelopment() {
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="bg-[#27232c] md:pb-10 ">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}> Software Development</span>{" "}
                as service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Building the right software suitable to your needs
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Introduction{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4">
              We assist enterprises to improve their efficiency and streamline
              business operations with the help of our high-quality software
              products. The right software products can set new ways of working
              and can leverage the business to the next level.{" "}
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] font-san md:text-[40px] text-[20px] font-[500] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                Our Services{" "}
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 mb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[14px] font-[300] md:mt-1 mt-4 md:ml-12 ml-6">
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Quest towards innovation
              </b>{" "}
              <br />
              <br />
              Our team is always looking for new tools for this new era of
              digital product design. We at Project42 Labs are updated with the
              latest technology and we research on ways to integrate them into
              our existing products in order to create maximum technology
              leverage.
              <br />
              <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB]">
                Business-centric approach
              </b>
              <br />
              <br />
              We put special effort into understanding how customers will
              welcome and use new software products and features. Simulating
              customer environment, researching on customer demands and creating
              logic to help us serve the perception and emotions of consumers
              better.
              <br />
              The customer-centric approach during product development is one of
              the determining factors we follow as a deciding principle for
              software's success.
              <br /> <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Our team of experts
              </b>{" "}
              <br />
              <br />
              The teamwork of our software engineers, designers and product
              managers has created many amazing products with great
              functionality. Through their problem-solving skills and deep
              knowledge, our crew has mastered the process of software product
              development. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                Digital Productivity
              </b>{" "}
              <br />
              <br />
              These days, software plays an important role in regulating
              business undertakings, eliminating human errors and increasing
              productivity. Business invoicing programs, CRM software,
              databases, all help to run businesses in a constructive manner.
              <br />
              Through our software products, we help our clients to create
              maximum value from their business systems through cost-effective
              methods. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                To adapt to changes
              </b>{" "}
              <br />
              <br />
              The scalable software that we built saves time and money for our
              clients in the long run. Our software products are able to adapt
              to the ever-changing needs of businesses and market and also
              ensure minimum maintenance level.
              <br />
              Along with better user experience and higher agility, our products
              also scale to a vast number of users. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                To assist all industries
              </b>{" "}
              <br />
              <br />
              Our years of knowledge in custom software development with
              industry-specific technical expertise has helped our clients, from
              various industries, by delivering ingenious solutions for them.
              The software solutions that we provide range from mobile
              application development to web applications, websites and more.
              <br />
              Each of our software designs is equipped with simplicity, high
              performance, reliability and security. <br /> <br />
              <br />
              <b className="border-l-[5px] pl-3 md:text-[30px] border-[#A71AFB] ">
                To stand the test of time
              </b>{" "}
              <br />
              <br />
              We deliver our projects at a faster rate while preserving the code
              quality. Flexible code components are signs of good quality
              design. The software programs that we create are flexible and
              coordinate with the changing requirements over the period, ruling
              out the factor of being left behind in the course of time. <br />{" "}
              <br />
              <br />
            </p>
            {/* </div> */}
          </div>
        </div>

        <div className="md:px-20 px-6">
          <div className="md:flex justify-between items-center md:my-[50px] my-10">
            {/* <div className=""> */}
            <h3 className="text-[#FFFFFF] md:text-[40px] text-[20px] text-left">
              <b className="border-l-[5px] pl-3 md:text-[40px] border-[#A71AFB] ">
                {" "}
                What Project42 Labs can do for you?
              </b>
            </h3>
            {/* </div> */}
          </div>
        </div>
        <div className="md:px-20 px-6 pb-10">
          <div className="md:flex flex-col md:flex-row justify-between md:mt-2 mt-10">
            {/* <div className="md:w-[100%]"> */}
            <p className="text-[#FFFFFF] md:text-[20px] text-[12px] font-[300] md:mt-1 mt-4">
              From website development, custom dashboards, mobile applications
              development, data analysis, & big data we have helped and are
              continuing to help many small and large enterprises. They've given
              better exposure, for our clients' products and services, to the
              right consumers.{" "}
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden hidden w-full transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4">Contact Us</li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
