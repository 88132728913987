import React, { useState, useRef } from "react";
import background from "../../assets/images/contactbackground.png";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { Link, useNavigate } from "react-router-dom";
import bannar from "../../assets/images/Contact us.svg";

import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactUs() {
  const navigate = useNavigate();
  const form = useRef();


  const sendEmail = (e) => {
    
    e.preventDefault();

    if(form.current.name?.value || form.current.email?.value || form.current.phone?.value || form.current.companyName?.value) {
      emailjs
      .sendForm(
        "service_2ae7d6c",
        "template_lx43t8t",
        form.current,
        "oyFriKlv199NQPoqv"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message Sent Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        },
        (error) => {
          console.log(error.text);
        }
      );
    } else {
      toast.warn('Please fill Required field!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

    
  };
  const [modals, setModals] = useState(false);
  const style = {
    background:
      "linear-gradient(0deg, rgba(39,35,44,1) 76%, rgba(64,5,58,1) 92%, rgba(39,35,44,1) 100%)",
  };

  return (
    <>
      <Navbar setModals={setModals} modals={modals} />
      <div style={style} className="w-full relative md:static pb-10">
        <div className=" md:pl-20 md:pb-[100px] bg-cover  md:bg-right md:h-[579px] h-[240px] ">
          <div className="w-full md:h-[580px] h-[242px] flex flex-row items-center">
            <div className="md:static pl-0 md:pl-0 md:pr-[60px] w-[50%]">
              <h3 className="text-white md:text-[40px] text-[16px] font-Philosopher font-medium text-left font-noto pl-6">
                <span style={{ color: "#A71AFB" }}>Contact Us</span> as service
              </h3>
              <h3 className="text-white md:text-[30px] text-[14px] font-Philosopher font-medium text-left font-noto pl-6 mt-4">
                Shape a future where possibilities are endless
              </h3>
            </div>
            <div className="md:w-1/2 w-1/2">
              <img
                src={bannar}
                className="md:mx-[auto] md: w-[60%] md:h-[70%]"
              ></img>
            </div>
          </div>
        </div>
        {/* <img src={background} alt="" className="w-full md:relative" /> */}
        <div className="md:hidden absolute top-2 border-l-[3px] border-[#A71AFB] mx-6 mt-10">
          {/* <h3 className="text-[16px] text-[#FFFFFF] font-medium ml-4 w-[200px]">
          Shape a future where possibilities are endless
          </h3> */}
          {/* <p className="text-[12px] text-[#FFFFFF] font-[500] ml-4  mt-3 tracking-wider w-[260px]">
            Please Send Us Your Details, We Shall Contact You Within 24 Hours.
            We Keep Your Details Confidential.
          </p> */}
        </div>
        {/* <div className="bg-black md:bg-none px-6 pb-6 md:px-0 md:pb-0"> */}
        <form
          ref={form}
          onSubmit={sendEmail}
          className=" bg-[#00000000] text-white border-[1px] shadow-sm  rounded-3xl border-solid mt-[20px] border-[#A71AFB] md:mt-10 md:w-[850px]  md:right-14 md:top-[180px] md:mx-[20%] md:py-[50px] md:px-[100px] px-8 py-6"
        >
          <div className="border-l-[3px] border-[#A71AFB] flex-col justify-center items-start md:flex">
            <h3 className="md:text-[40px] text-[20px] font-medium ml-4">
              Shape a future where possibilities are endless
            </h3>
            {/* <p className="text-[15px] font-[500] ml-4 ">
                    Please Send Us Your Details, We Shall Contact You Within 24
                    Hours. We Keep Your Details Confidential.
                  </p> */}
          </div>
          <div className="flex justify-end md:mt-3">
            <h3 className="text-[10px] font-[500]">*Required fields</h3>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-[100px] gap-y-9 mt-5">
            <div className="md:w-[277px]">
              <h2 className="text-[14px] font-medium mb-4">Name*</h2>
              <input
                type="text"
                name="name"
                className="border-b-[2px] bg-transparent outline-none md:w-[277px] w-full pb-2 border-[#ffffff]"
                placeholder="Enter Your Name"
              />
            </div>{" "}
            <div className="md:w-[277px]">
              <h2 className="text-[14px] font-medium mb-4 ">E-mail*</h2>
              <input
                type="text"
                name="email"
                className="bg-transparent border-b-[2px] md:w-[277px] w-full outline-none pb-2 border-[#ffffff]"
                placeholder="Enter Your Email"
              />
            </div>{" "}
            <div className="md:w-[277px]">
              <h2 className="text-[14px] font-medium mb-4 ">Phone*</h2>
              <input
                type="text"
                name="phone"
                className="border-b-[2px] bg-transparent md:w-[277px] w-full outline-none pb-2 border-[#ffffff]"
                placeholder="Enter Your Phone"
              />
            </div>{" "}
            {/* <div className="md:w-[277px]">
                  <h2 className="text-[14px] font-medium mb-4">*Looking for</h2>
                  <div className="flex items-center mt-7">
                    <input
                      id="default-radio-1"
                      type="radio"
                      value="solution"
                      name="lookingFor"
                      className="w-4 h-4 bg-black text-white bg-gray-100 border-gray-300 focus:ring-none dark:focus:ring-none dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      className="ml-2 text-sm font-medium text-white-900 dark:text-white-300 "
                    >
                      Solutions
                    </label>
                    <input
                      id="default-radio-1"
                      type="radio"
                      value="job"
                      name="lookingFor"
                      className="w-4 h-4 bg-black text-white bg-gray-100 border-white-300 focus:ring-none dark:focus:ring-none dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-white-600 ml-10"
                    />
                    <label
                      for="default-radio-1"
                      className="ml-2 text-sm font-medium text-white-900 dark:text-white-300 "
                    >
                      Jobs
                    </label>
                  </div>
                </div> */}
            <div className="md:w-[277px] ">
              <h2 className="text-[14px] font-medium mb-4 ">Company Name*</h2>
              <input
                type="text"
                name="companyName"
                className="border-b-[2px] bg-transparent md:w-[277px] w-full outline-none pb-2 border-[#ffffff]"
                placeholder="Enter Your Company's name"
              />
            </div>{" "}
          </div>
          {/* <div className="mt-[60px] md:flex md:gap-[70px] md:ml-[-10px] md:mr-[-20px]">
                <div className="h-[1.5px] md:w-[327px] bg-slate-400"></div>
                <div className="h-[1.5px] md:w-[327px] bg-slate-400"></div>
              </div> */}
          {/* <div className="md:flex justify-between mt-9">
                <div className="md:w-[277px]">
                  <h2 className="text-[14px] font-medium mb-4">Applying for</h2>
                  <select
                    className="border-b-2 bg-[#27232C] outline-none md:w-277 w-full pb-2 border-white hover:bg-white-100"
                    defaultValue=""
                    name="ApplyFor"
                  >
                    <option value="" className="text-[#f6f634]" disabled hidden>
                      Select an option
                    </option>
                    <option value="Frontend Developer" className="py-1">
                      Frontend Developer
                    </option>
                    <option value="Backend Developer" className="py-1">
                      Backend Developer
                    </option>
                    <option value="UI/UX Developer" className="py-1">
                      UI/UX Developer
                    </option>
                    <option value="Product Management" className="py-1">
                      Product Management
                    </option>
                    <option value="Data Scientist" className="py-1">
                      Data Scientist
                    </option>
                  </select>
                </div>
                <div className="md:w-[277px]">
                  <h2 className="text-[14px] font-medium mb-4">
                    Upload Resume
                  </h2>
                  <label htmlFor="fileInput" className="cursor-pointer">
                    <span className="border-b-2 md:w-277 w-full outline-none border-[#A71AFB] text-white bg-[#A71AFB] w-20 h-9 flex justify-center items-center">
                      Browse
                    </span>
                    <input
                      type="file"
                      id="fileInput"
                      className="hidden"
                      name="my_file"
                      // onChange={handleFileSelect}
                    />
                  </label>
                </div>
              </div> */}
          <div className="md:w-[277px] mt-9">
            <h2 className="text-[14px] font-medium mb-4 ">Designation</h2>
            <input
              type="text"
              name="designation"
              className="border-b-[2px] bg-transparent md:w-[277px] w-full outline-none pb-2 border-[#ffffff]"
              placeholder="Enter Your Designation"
            />
          </div>{" "}
          <div className="mt-9">
            <h3 className="text-[14px] font-medium mb-4">Message</h3>
            <textarea
              placeholder="Please type here...."
              rows="10"
              name="message"
              className="p-2 bg-transparent px-3 w-full max-h-[120px] border-[#ffffff] border-[1.5px]"
            ></textarea>
          </div>
          <div className="flex flex-col justify-center items-center mt-9">
            <button
              type="submit"
              className="text-[#FFFFFF] bg-[#A71AFB] w-[138px] h-[36px] rounded-lg"
            >
              Send
            </button>
          </div>
        </form>
        {/* </div> */}
      </div>

      <div
        className={`${
          modals ? "translate-y-[0px]" : "translate-y-[240px]"
        } md:hidden w-full hidden transition-all duration-500 h-[287px] bg-black  fixed bottom-0 py-6 px-9`}
      >
        <div className="flex flex-row items-center justify-center">
          <div
            className="w-[48px] h-[3px] bg-[#5A5B5B]"
            onClick={() => {
              setModals(!modals);
            }}
          ></div>
        </div>
        <ul className="text-[#FFFFFF] text-[14px] font-[500] mt-12">
          <li>Services</li>
          <li className="mt-4">Solutions</li>
          <li className="mt-4">Work</li>
          <li className="mt-4">Company</li>
          <li className="mt-4" onClick={() => navigate("/contactus")}>
            Contact Us
          </li>
        </ul>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
}
